import django_session from "@/api/django_session";
import store from "@/store";

export const saveSalesCall = async (salesCallData, editSalesCallYN) => {
  console.log(editSalesCallYN);

  var utcTime = new Date(salesCallData.call_date).toISOString();
  console.log(utcTime);

  let reminderUTC = null;
  let dueDateUTC = null;
  if (salesCallData.activity_follow_up_yn) {
    reminderUTC = new Date(salesCallData.activity_reminder_date).toISOString();
    dueDateUTC = new Date(salesCallData.activity_due_date).toISOString();
  }

  let salescall = undefined;

  if (editSalesCallYN) {
    await django_session
      .patch("erp/customer/sales-call/" + salesCallData.sales_call_id + "/", {
        sales_call_id: salesCallData.sales_call_id,
        call_date: utcTime,
        user: salesCallData.user,
        contact: salesCallData.contact,
        customer: salesCallData.customer,
        category: salesCallData.category,
        notes: salesCallData.notes,
        follow_up_yn: salesCallData.activity_follow_up_yn,
        reminder_date: reminderUTC,
        due_date: dueDateUTC,
        task_subject: salesCallData.activity_task_subject,
        assign_task_to: salesCallData.activity_assign_to,
        follow_up_note: salesCallData.activity_follow_up_note,
        create_outlook_task_yn: salesCallData.activity_create_outlook_task_yn,
        outlook_to_do_list: salesCallData.outlook_to_do_list,
        updated_by: store.state.auth.user.user_id,
      })
      .then((response) => {
        // console.log(response);
        salescall = response;
      })
      .catch((error) => {
        // console.log(error);
        salescall = error.response;
      });
  }
  if (!editSalesCallYN) {
    await django_session
      .post("erp/customer/sales-call/create/", {
        is_new_contact_yn: salesCallData.is_new_contact_yn,
        call_date: utcTime,
        user: salesCallData.user,
        contact: salesCallData.contact,
        customer: salesCallData.customer,
        category: salesCallData.category,
        notes: salesCallData.notes,
        follow_up_yn: salesCallData.activity_follow_up_yn,
        due_date: dueDateUTC,
        reminder_date: reminderUTC,
        task_subject: salesCallData.activity_task_subject,
        assign_task_to: salesCallData.activity_assign_to,
        follow_up_note: salesCallData.activity_follow_up_note,
        create_outlook_task_yn: salesCallData.activity_create_outlook_task_yn,
        outlook_to_do_list: salesCallData.outlook_to_do_list,
        created_by: store.state.auth.user.user_id,
        modified_by: store.state.auth.user.user_id,
      })
      .then((response) => {
        salescall = response;
      })
      .catch((error) => {
        salescall = error.response;
      });
  }
  console.log("Sales Call Function", salescall);
  return salescall;
};
