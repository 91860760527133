<template>
  <div id="company-detail-page">
    <div v-if="!showLoader">
      <m-page-title title="Customer Detail" />
      <b-row class="my-1">
        <b-alert
          v-model="showAlert"
          :variant="alertVariant"
          class="w-100"
          dismissible
          >{{ alertMessage }}</b-alert
        >
      </b-row>
      <div id="company-details">
        <b-row class="my-3 align-items-center">
          <b-colxx md="3" sm="12">
            <m-text-input
              id="customer-name"
              label="Company Name"
              :rules="{ required: true, max: 125 }"
              :value="customerDetail.customer_name"
              :alternative="false"
              :group="false"
              :readonly="!isProspect"
              name="Company Name"
              @update-value="
                (input) => {
                  customerDetail.customer_name = input;
                }
              "
            />
          </b-colxx>
          <b-colxx v-if="!isProspect" md="3" sm="11">
            <m-text-input
              id="customer-sage-id-name"
              label="Sage Customer ID"
              :value="customerDetail.sage_scntcname"
              :alternative="false"
              :group="false"
              readonly
              name="Sage Customer ID"
            />
          </b-colxx>
          <b-colxx md="1" sm="1">
            <span
              v-if="customerDetail.is_inactive_yn"
              class="ml-1"
              :style="{ display: 'inline-block', color: 'red' }"
              ><i class="fas fa-do-not-enter"></i
            ></span>
          </b-colxx>
          <b-colxx md="2" sm="12">
            <b-form-group
              id="is-inactive-fieldset"
              label="Is Inactive"
              label-for="inactive-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="inactive-yn"
                :disabled="!canEditCustomer || disableInactiveSwitch"
                v-model="customerDetail.is_inactive_yn"
              ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
          <b-colxx v-if="showPriceListButton" md="2" sm="12">
            <b-button
              class="bg-purple text-white float-right"
              size="sm"
              @click="showPriceList()"
              >Price List</b-button
            >
          </b-colxx>
          <b-colxx v-if="isProspect" md="6" sm="12">
            <b-button
              class="bg-purple text-white float-right"
              size="sm"
              @click="convertProspect()"
              >Merge Prospect</b-button
            >
            <b-button
              class="bg-red text-white float-right mx-1"
              size="sm"
              @click="
                () => {
                  showConfirmDeleteProspect = true;
                }
              "
              >Delete Propsect</b-button
            >
          </b-colxx>
          <!-- <b-colxx v-if="isProspect" md="2" sm="12">
            <b-button
              class="bg-red text-white float-right"
              size="sm"
              @click="() => { showConfirmDeleteProspect = true }"
              >Delete Propsect</b-button
            >
          </b-colxx> -->
        </b-row>
        <b-row>
          <b-colxx md="4" sm="12">
            <m-text-input
              id="prospect-address-1"
              label="Address 1"
              :rules="{ max: 100 }"
              :value="customerDetail.address_1"
              :alternative="false"
              :group="false"
              name="Address 1"
              :readonly="!isProspect"
              @update-value="
                (input) => {
                  customerDetail.address_1 = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="4" sm="12">
            <m-text-input
              id="prospect-address-2"
              label="Address 2"
              :rules="{ max: 100 }"
              :value="customerDetail.address_2"
              :alternative="false"
              :group="false"
              name="Address 2"
              :readonly="!isProspect"
              @update-value="
                (input) => {
                  customerDetail.address_2 = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="4" sm="12">
            <m-text-input
              id="prospect-city"
              label="City"
              :rules="{ max: 75 }"
              :value="customerDetail.city"
              :alternative="false"
              :group="false"
              name="City"
              :readonly="!isProspect"
              @update-value="
                (input) => {
                  customerDetail.city = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="2" sm="12">
            <b-form-group
              id="prov-type-fieldset"
              label="Province"
              label-for="prov-select"
              class="field-label-form-group mt-2"
            >
              <b-form-select
                id="prov-select"
                v-model="customerDetail.province"
                :options="province"
                :disabled="!isProspect"
              ></b-form-select>
            </b-form-group>
          </b-colxx>
          <b-colxx md="3" sm="12">
            <m-text-input
              id="prospect-postalcode"
              label="Postal Code"
              :rules="{ max: 7 }"
              :value="customerDetail.postal_code"
              :alternative="false"
              :group="false"
              :readonly="!isProspect"
              name="Postal Code"
              @update-value="
                (input) => {
                  customerDetail.postal_code = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="3" sm="12">
            <m-text-input
              id="prospect-phone"
              label="Phone"
              :rules="{ max: 50 }"
              type="phone"
              :value="customerDetail.phone"
              :alternative="false"
              :group="false"
              name="Phone"
              :readonly="!isProspect"
              @update-value="
                (input) => {
                  customerDetail.phone = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="4" sm="12">
            <m-text-input
              id="prospect-website"
              label="Website"
              :rules="{ max: 100 }"
              :value="customerDetail.website"
              :alternative="false"
              :group="false"
              name="Website"
              :readonly="!isProspect"
              @update-value="
                (input) => {
                  customerDetail.website = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="3" sm="12">
            <b-form-group
              id="is-rental-depot-fieldset"
              label="Is Rental Depot"
              label-for="rental-depot-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="rental-depot-yn"
                :disabled="!canEditCustomer"
                v-model="customerDetail.is_rental_depot_yn"
              ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
          <b-colxx md="3" sm="12">
            <m-text-input
              id="rental-depot-date"
              label="Rental Depot Date"
              type="date"
              :value="customerDetail.date_rental_depot_active"
              :alternative="false"
              :group="false"
              :rules="{ required: customerDetail.is_rental_depot_yn }"
              name="Rental Depot Date"
              :disabled="!canEditCustomer"
              @update-value="
                (input) => {
                  customerDetail.date_rental_depot_active = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="3" sm="12">
            <b-form-group
              id="is-pump_insurance_flag"
              label="Insured"
              label-for="insured-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="insured-yn"
                :disabled="!canEditCustomer"
                v-model="customerDetail.pump_insurance_yn"
              ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
        </b-row>
        <div class="d-flex my-2 mr-auto">
          <span
            v-for="item in customerDetail.categories"
            :key="item.category_id"
            class="badge text-white p-2 mr-1"
            :style="{ 'background-color': `${item.category_colour}` }"
            >{{ item.customer_category_name }}</span
          >
          <span v-if="isProspect" class="badge bg-orange text-white inline ml-2"
            >Prospect</span
          >
        </div>
        <b-row class="mt-2 align-items-center">
          <b-colxx md="4" sm="12">
            <b-form-group
              id="territory-type-fieldset"
              label="Territory"
              label-for="territory-select"
              class="field-label-form-group"
            >
              <b-form-select
                id="territory-select"
                v-model="customerDetail.sales_territory_id"
                :options="filteredSalesTerritoryList"
                value-field="territory_id"
                text-field="territory_name"
                :disabled="!canEditCustomer"
                @change="updateCustomerTerritory()"
              ></b-form-select>
            </b-form-group>
          </b-colxx>
          <b-colxx md="4" sm="12"
            ><b-form-group
              id="salesrep-type-fieldset"
              label="Sales Rep"
              label-for="salesrep-name"
              class="field-label-form-group"
            >
              <b-form-input
                id="salesrep-name"
                v-model="customerDetail.sales_rep_name"
                disabled
              ></b-form-input> </b-form-group
          ></b-colxx>
          <b-colxx
            md="4"
            sm="12"
            v-if="permissions.includes('CanEditCustomer')"
            class="d-row align-items-center"
          >
            <b-button
              variant="success"
              class="float-right"
              :disabled="saveInProgress"
              @click="saveCustomerProfile()"
              >{{ saveButtonLabel }}</b-button
            >
          </b-colxx>
        </b-row>
      </div>

      <div id="tabs-section">
        <b-row class="mt-2"
          ><b-colxx cols="12">
            <b-card no-body>
              <b-tabs v-model="companyTabIndex" pills card>
                <b-tab
                  v-if="permissions.includes('CanViewSalesCall')"
                  active
                  title="Sales Calls"
                >
                  <div v-if="showSalesCallModal">
                    <sales-call
                      ref="__salesCallDetail"
                      :item="editSalesCall"
                      :user-name="user.full_name"
                      :user-id="user.user_id"
                      :is-edit-sales-call="editSalesCallYN"
                      :show-contact-search="false"
                      :contact-dropdown-yn="!editSalesCallYN"
                      :contact-options="customerDetail.contacts"
                      @update-call-data="updateSalesCall($event)"
                    />
                    <div class="d-flex justify-content-end my-4">
                      <b-button
                        class="mr-2"
                        variant="info"
                        :disabled="salesCallIsSaving || !salesCallFormIsValid"
                        @click="salesCallSave()"
                      >
                        {{ salesCallSaveButtonLabel }}
                      </b-button>
                      <b-button
                        variant="warning"
                        :disabled="salesCallIsSaving"
                        @click="closeSalesCall()"
                        >Close</b-button
                      >
                    </div>
                  </div>
                  <b-row>
                    <b-colxx cols="12"
                      ><b-table
                        id="sales-call-table"
                        ref="__salesCallTable"
                        head-variant="dark"
                        responsive
                        :items="customerDetail.sales_calls"
                        :fields="sales_call_list_fields"
                        per-page="0"
                      >
                        <template #head(actions)>
                          <span v-if="canCreateSalesCall">
                            <i
                              class="fas fa-square-plus icon-pointer text-primary text-white"
                              @click="createNewSalesCall()"
                            />
                          </span>
                        </template>
                        <template #cell(actions)="row">
                          <i
                            class="fas fa-eye icon-pointer text-info mr-2"
                            @click="goSalesCallDetail(row.item, row.index)"
                          ></i>
                        </template>
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{
                              width: field.key === 'notes' ? '300px' : '',
                            }"
                          />
                        </template>
                        <template #cell(call_date)="row">
                          {{ new Date(row.item.call_date).toLocaleString() }}
                        </template>
                      </b-table>
                    </b-colxx>
                  </b-row>
                </b-tab>
                <b-tab v-if="canViewContact" title="Contacts">
                  <b-row>
                    <b-colxx cols="12"
                      ><b-table
                        id="contact-list-table"
                        ref="contactListTable"
                        responsive
                        :items="customerDetail.contacts"
                        :fields="contact_list_fields"
                        head-variant="dark"
                      >
                        <template #head(actions)>
                          <span v-if="canCreateContact">
                            <i
                              class="fas fa-square-plus icon-pointer text-primary text-white"
                              @click="createNewContact()"
                            />
                          </span>
                        </template>
                        <template #cell(actions)="row">
                          <span v-if="canViewContact">
                            <i
                              class="fas fa-eye icon-pointer text-success mr-2"
                              @click="updateContact(row.item, row.index)"
                            />
                          </span>
                          <span v-if="canCreateSalesCall">
                            <i
                              class="fas fa-phone-plus icon-pointer text-primary"
                              @click="openCreateSalesCall(row.item, row.index)"
                            />
                          </span>

                          <i
                            v-if="row.item.is_active_yn == false"
                            class="fas fa-do-not-enter text-warning ml-2"
                          />
                        </template> </b-table
                    ></b-colxx>
                  </b-row>
                </b-tab>

                <!--  -->
                <!-- Pump Tab -->
                <!--  -->
                <b-tab title="Pumps">
                  <b-row>
                    <b-colxx cols="12"
                      ><b-table
                        id="pump-list-table"
                        ref="pumpListTable"
                        responsive
                        :items="customerDetail.pump_list"
                        :fields="pump_list_fields"
                        head-variant="dark"
                      >
                        <template #cell(action)="row">
                          <i
                            class="fas fa-eye icon-pointer text-info mr-2"
                            @click="goPumpDetail(row.item)"
                          />
                        </template>
                        <template #cell(is_leased_yn)="row">
                          <i
                            class="fas"
                            :class="
                              row.item.is_leased_yn
                                ? 'fa-circle-check text-success'
                                : 'fa-circle-xmark'
                            "
                          />
                        </template> </b-table
                    ></b-colxx>
                  </b-row>
                </b-tab>

                <!--  -->
                <!-- Sales Order Tab -->
                <!--  -->
                <b-tab v-if="canViewSalesInvoices" title="Sales & Orders">
                  <form
                    id="search-invoice-form"
                    @submit.prevent="getCustomerInvoices"
                  >
                    <div class="d-flex mb-2 align-items-center">
                      <div class="flex-column me-auto flex-grow-1">
                        <b-form-group
                          label="Search"
                          class="field-label-form-group mr-2"
                        >
                          <b-form-input
                            v-model="filterSalesOrderText"
                            placeholder="Search by reference #"
                            type="text"
                          />
                        </b-form-group>
                      </div>
                      <div class="flex-column me-auto flex-shrink ml-3">
                        <b-button
                          variant="success"
                          class="mr-2"
                          @click="getCustomerInvoices()"
                          >Search</b-button
                        >
                        <b-button
                          variant="info"
                          @click="
                            () => {
                              filterShipToAddress = null;
                              filterSalesOrderText = '';
                              current_page = 1;
                              getCustomerInvoices();
                            }
                          "
                          >Clear</b-button
                        >
                      </div>
                    </div>
                  </form>
                  <div v-if="gettingCustomerInvoices">
                    <div class="d-flex justify-content-center">
                      <p>Getting Customer Invoices...</p>
                    </div>
                  </div>
                  <div v-if="!gettingCustomerInvoices">
                    <table id="so-table" class="table" width="100%">
                      <thead class="table-header">
                        <tr>
                          <th class="table-header-cell" width="20%">Date</th>
                          <th class="table-header-cell" width="40%">Inv #</th>
                          <th class="table-header-cell" width="40%">Amount</th>
                        </tr>
                      </thead>
                      <tbody class="table-body">
                        <template v-for="invoice in invoice_list">
                          <tr :key="invoice.lid" class="table-row">
                            <td class="table-cell">
                              {{ invoice.invoice_date }}
                            </td>
                            <td class="table-cell">{{ invoice.ssource1 }}</td>
                            <td class="table-cell">
                              {{ invoice.invoice_total }}
                            </td>
                          </tr>
                          <tr
                            :key="invoice.lid + 'details'"
                            class="invoice-detail-row"
                          >
                            <td></td>
                            <td colspan="2">
                              <table
                                id="sales-line-item-table"
                                class="inner-table"
                              >
                                <thead class="inner-table-header">
                                  <tr>
                                    <th
                                      class="inner-table-header-cell"
                                      width="50%"
                                    >
                                      <em>Item</em>
                                    </th>
                                    <th class="inner-table-header-cell">
                                      <em>Qty</em>
                                    </th>
                                    <th class="inner-table-header-cell">
                                      <em>Price</em>
                                    </th>
                                    <th class="inner-table-header-cell">
                                      <em>Total</em>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="inner-table-body">
                                  <tr
                                    v-for="line in invoice.invoice_lines"
                                    :key="line.nlinenum"
                                    class="inner-table-row"
                                  >
                                    <td class="inner-table-cell">
                                      {{ line.item_description }}
                                    </td>
                                    <td class="inner-table-cell">
                                      {{ line.dqty }}
                                    </td>
                                    <td class="inner-table-cell">
                                      {{ line.formatted_price }}
                                    </td>
                                    <td class="inner-table-cell">
                                      {{ line.formatted_amount }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <b-pagination
                    v-if="soPageTotal !== 1"
                    v-model="current_page"
                    :total-rows="total_rows"
                    :per-page="per_page"
                    :number-of-pages="soPageTotal"
                    size="sm"
                    class="my-0"
                    aria-controls="so-table"
                    @input="getCustomerInvoices(current_page)"
                  ></b-pagination>
                </b-tab>

                <!--  -->
                <!-- Related Records Tab -->
                <!--  -->
                <b-tab v-if="hasRelatedCustomerRecords" title="Related Records"
                  ><b-table
                    id="related-records-table"
                    ref="__relatedRecordsTable"
                    responsive
                    :items="customerDetail.related_company_records"
                    :fields="related_company_records_fields"
                    head-variant="dark"
                  >
                    <template #cell(action)="row">
                      <i
                        class="fas fa-eye icon-pointer text-info mr-2"
                        @click="goRelatedCompanyDetail(row.item)"
                      />
                    </template>
                  </b-table>
                </b-tab>
                <!--  -->
                <!-- Documents Tab -->
                <!--  -->
                <b-tab title="Documents">
                  <b-alert
                    v-if="documentUploadAlert"
                    :variant="documentUploadAlertVariant"
                    dismissible
                    show
                    >{{ documentUploadAlertMessage }}</b-alert
                  >
                  <div class="row my-2 align-items-center">
                    <b-colxx md="9" sm="9">
                      <b-form-file
                        id="upload-file"
                        v-model="newFile.file"
                        :state="Boolean(newFile.file)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="getFileParams($event)"
                      ></b-form-file>
                    </b-colxx>
                    <b-colxx md="3" sm="3">
                      <div v-if="isUploading">Please Wait...</div>
                      <b-button
                        v-if="!isUploading"
                        class="btn btn-success"
                        :disabled="!newFile.file"
                        @click="uploadDocument()"
                      >
                        Upload</b-button
                      >
                    </b-colxx>
                  </div>
                  <div class="row mt-4">
                    <b-table
                      id="document-table"
                      ref="__documentTable"
                      responsive
                      head-variant="dark"
                      :items="customerDetail.documents"
                      :fields="document_list_fields"
                      per-page="0"
                    >
                      <template #cell(actions)="row">
                        <div>
                          <i
                            class="fas fa-eye icon-pointer text-primary"
                            @click="viewDocument(row.item)"
                          ></i>
                          <i
                            class="fas fa-trash-alt icon-pointer text-danger ml-2"
                            @click="confirmDeleteDocument(row.item, row.index)"
                          ></i>
                        </div>
                        <div v-if="selectedDocumentId == row.item.document_id">
                          <b-button
                            class="btn btn-danger btn-sm"
                            @click="deleteDocument(row.item, row.index)"
                            >Confirm</b-button
                          >
                          <b-button
                            class="btn btn-info btn-sm"
                            @click="
                              () => {
                                (showConfirmDeleteDocument = false),
                                  (selectedDocumentId = null),
                                  (selectedDocumentIndex = null);
                              }
                            "
                            >Cancel</b-button
                          >
                        </div>
                      </template>
                      <template #cell(created_at)="row">
                        {{ new Date(row.item.created_at).toLocaleDateString() }}
                      </template>
                    </b-table>
                  </div>
                </b-tab>
                <!--  -->
                <!-- Categories tab -->
                <!--  -->
                <b-tab v-if="canEditCustomer" title="Categories">
                  <div class="row my-3">
                    <div class="col-12">
                      <span
                        v-for="(item, index) in categoryList"
                        :key="item.category_id"
                      >
                        <base-checkbox
                          :item="item"
                          :index="index"
                          :checked="item.checked"
                          @update-checkbox="updateCategoryCheckbox(item, index)"
                          >{{ item.customer_category_name }}</base-checkbox
                        >
                      </span>
                    </div>
                  </div>
                </b-tab>
                <!-- -->
                <!-- Ship To addresses tab -->
                <!-- -->
                <b-tab title="Ship-To Addresses">
                  <div>
                    <b-table
                      id="shipto-addresses-table"
                      responsive
                      head-variant="dark"
                      :items="customerDetail.ship_to_addresses"
                      :fields="ship_to_fields"
                      per-page="0"
                    >
                      <template #cell(action)="{ item }">
                        <b-form-checkbox
                          :id="'show-on-website' + item.lid"
                          v-model="item.display_on_website_yn"
                          @change="updateShipToAddress(item)"
                        />
                      </template>
                    </b-table>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-colxx>
        </b-row>
      </div>
    </div>

    <!--  -->
    <!-- View/Create Contact Modal -->
    <!--  -->
    <div id="contact-modal-div">
      <validation-observer ref="__validateContactForm" v-slot="observer">
        <b-modal
          id="contact-modal"
          v-model="showContactModal"
          title="Contact Details"
          centered
          scrollable
          header-bg-variant="info"
          header-text-variant="white"
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          size="xl"
        >
          <contact
            ref="__contactDetail"
            :item="editContact"
            :edit-contact-y-n="editContactYN"
            :customer-locations="customerLocations"
            :company-detail="customerDetail"
            :show-edit-company="false"
            @update-contact-data="updateContactData($event)"
            @delete-contact="deleteContact($event)"
          />
          <template #modal-footer="{}">
            <b-button
              v-if="!saveContactComplete"
              class="mr-2"
              variant="info"
              :disabled="contactIsSaving || observer.invalid"
              @click="validateContactForm()"
            >
              {{ contactSaveButtonLabel }}
            </b-button>
            <b-button
              variant="warning"
              :disabled="contactIsSaving"
              @click="closeContact()"
              >Close</b-button
            >
          </template>
        </b-modal>
      </validation-observer>
    </div>

    <!--  -->
    <!-- Create Sales Call Modal -->
    <!--  -->
    <!-- <div id="sales-call-modal-div"> -->
    <!--   <b-modal -->
    <!--     id="sales-call-modal" -->
    <!--     v-model="showSalesCallModal" -->
    <!--     title="New Sales Call" -->
    <!--     centered -->
    <!--     no-close-on-esc -->
    <!--     no-close-on-backdrop -->
    <!--     hide-header-close -->
    <!--     scrollable -->
    <!--     header-bg-variant="info" -->
    <!--     header-text-variant="white" -->
    <!--     size="lg" -->
    <!--   > -->
    <!--     <sales-call -->
    <!--       ref="__salesCallDetail" -->
    <!--       :item="editSalesCall" -->
    <!--       :user-name="user.full_name" -->
    <!--       :user-id="user.user_id" -->
    <!--       :is-edit-sales-call="editSalesCallYN" -->
    <!--       :show-contact-search="false" -->
    <!--       @update-call-data="updateSalesCall($event)" -->
    <!--     /> -->
    <!--     <template #modal-footer="{}"> -->
    <!--       <div v-if="!viewSalesCall"> -->
    <!--         <b-button -->
    <!--           v-if="!saveSalesCallComplete" -->
    <!--           class="mr-2" -->
    <!--           variant="info" -->
    <!--           :disabled="salesCallIsSaving || !salesCallFormIsValid" -->
    <!--           @click="salesCallSave()" -->
    <!--         > -->
    <!--           {{ salesCallSaveButtonLabel }} -->
    <!--         </b-button> -->
    <!--       </div> -->
    <!---->
    <!--       <b-button -->
    <!--         variant="warning" -->
    <!--         :disabled="salesCallIsSaving" -->
    <!--         @click="closeSalesCall()" -->
    <!--         >Close</b-button -->
    <!--       > -->
    <!--     </template> -->
    <!--   </b-modal> -->
    <!-- </div> -->

    <!--  -->
    <!-- Price List Modal -->
    <!--  -->
    <div id="price-list-modal-div">
      <b-modal
        id="price-list-modal"
        v-model="priceListModal"
        :title="customerDetail.price_list_name"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg"
      >
        <div v-if="gettingPriceList">
          <div class="d-flex justify-content-center">
            <p>Getting Price List...</p>
          </div>
        </div>
        <div v-if="!gettingPriceList">
          <b-row class="my-3 align-items-center">
            <b-colxx md="12" sm="12">
              <!-- <b-button @click="showDetail = false">Hide</b-button> -->
              <b-form-group class="mb-0">
                <b-input-group class="">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fas fa-search"></i
                    ></span>
                  </div>
                  <b-form-input
                    v-model.trim="filterItemName"
                    placeholder="Search for an item by name"
                    type="text"
                  >
                  </b-form-input>
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i
                        class="fas fa-circle-xmark icon-pointer text-danger"
                        @click="filterItemName = ''"
                      ></i
                    ></span>
                  </div>
                </b-input-group>
              </b-form-group>
            </b-colxx>
          </b-row>

          <b-table
            id="price-list-detail-table"
            ref="__priceListDetailTable"
            responsive
            head-variant="dark"
            :items="customerPriceList.price_list_items"
            :fields="price_list_detail_fields"
            :current-page="current_page"
            :filter-included-fields="filterOn"
            :filter="filterItemName"
            per-page="0"
          >
          </b-table>
        </div>
      </b-modal>
    </div>

    <!--  -->
    <!-- View Document Modal -->
    <!--  -->
    <div id="view-document-modal-div">
      <b-modal
        id="view-document-modal"
        v-model="viewDocumentModal"
        title="Viewing Document"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg"
      >
        <img :src="viewImgSrc" />
      </b-modal>
    </div>

    <!--  -->
    <!-- Convert Prospect Modal -->
    <!--  -->
    <div id="convert-prospect-modal-div">
      <b-modal
        id="convert-prospect-modal"
        v-model="showConvertProspectModal"
        title="Convert Prospect to Customer"
        centered
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg"
      >
        <b-row>
          <b-colxx md="12" sm="12">
            <b-alert variant="warning" show>
              {{ mergeCustomerWarningMessage }}
            </b-alert>
          </b-colxx>
        </b-row>
        <b-row>
          <b-colxx md="12" sm="12">
            <b-alert
              v-if="convertCustomerComplete == true"
              variant="success"
              show
            >
              The prospect has been converted to a customer. When you close this
              modal, you will be redirected to the customer list page.
            </b-alert>
            <b-alert
              v-if="convertCustomerComplete == false"
              variant="danger"
              show
            >
              There was a problem converting the prospect to a customer. Please
              try again.
            </b-alert>
          </b-colxx>
        </b-row>
        <b-row class="my-2">
          <b-colxx md="12" lg="12">
            <b-form-group
              label="Prospect Merge Type:"
              v-slot="{ mergeTypeRadio }"
            >
              <b-form-radio-group
                id="merge-radio-group"
                v-model="prospectMergeType"
                :options="prospectMergeOptions"
                :aria-describedby="mergeTypeRadio"
                name="merge-radio-options"
              ></b-form-radio-group>
            </b-form-group>
          </b-colxx>
        </b-row>
        <div v-if="findCustomerInSage">
          <form @submit.prevent="searchSageCustomerNumber()">
            <b-row class="align-items-bottom">
              <b-colxx md="9" sm="9">
                <m-text-input
                  id="sage-customer-number"
                  label="Enter the Sage Customer Number"
                  :value="sageCustomerNumber"
                  :alternative="false"
                  :group="false"
                  name="Enter Sage Customer Number"
                  @update-value="
                    (input) => {
                      sageCustomerNumber = input;
                    }
                  "
                />
              </b-colxx>
              <b-colxx md="3" sm="3" class="mt-4">
                <b-button
                  class="btn btn-success"
                  @click="searchSageCustomerNumber()"
                  >Search</b-button
                >
              </b-colxx>
            </b-row>
          </form>
        </div>
        <div v-if="!findCustomerInSage">
          <b-alert
            v-if="showConvertProspectAlert"
            :variant="convertProspectAlertVariant"
            dismissible
            show
          >
            {{ convertProspectAlertMessage }}
          </b-alert>
          <b-row class="my-3 align-items-center">
            <b-colxx md="12" sm="12">
              <p>
                <strong
                  >Customer Name: {{ getSageCustomerForProspect.sname }}</strong
                >
              </p>
              <p>Address: {{ getSageCustomerForProspect.sstreet1 }}</p>
              <p>City: {{ getSageCustomerForProspect.scity }}</p>
              <p>Prov/State: {{ getSageCustomerForProspect.sprovstate }}</p>
              <p>Phone: {{ getSageCustomerForProspect.sphone1 }}</p>
            </b-colxx>
          </b-row>
        </div>
        <template #modal-footer>
          <div v-if="!showConfirmConvertProspect">
            <b-button
              class="btn btn-success"
              :disabled="disableConvertProspectButton"
              @click="confirmConvertProspect()"
              >Convert Prospect</b-button
            >
          </div>
          <div v-if="showConfirmConvertProspect">
            <b-button
              class="btn btn-danger"
              :disabled="disableConvertProspectButton"
              @click="convertProspectToCustomer()"
              >Please Confirm</b-button
            >
          </div>
          <b-button class="btn btn-info" @click="cancelConvertProspect()"
            >Cancel</b-button
          >
        </template>
      </b-modal>
    </div>

    <!--  -->
    <!-- Confirm Delete Prospect -->
    <!--  -->
    <div id="delete-prospect-modal-div">
      <b-modal
        id="delete-prospect-modal"
        v-model="showConfirmDeleteProspect"
        title="Confirm Delete Prospect"
        centered
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="warning"
        header-text-variant="white"
        size="lg"
      >
        <div v-if="!deleteInProgress">
          <b-alert
            v-if="showDeleteProspectAlert"
            :variant="deleteProspectAlertVariant"
            dismissible
            show
          >
            {{ deleteProspectAlertMessage }}
          </b-alert>
          <b-row class="my-3 align-items-center">
            <b-colxx md="12" sm="12">
              <p>
                You are about to delete this company record and all records
                associated with this company. Please confirm this action. This
                is irreversible!
              </p>
              <p>Contacts: {{ customerDetail.contacts.length }}</p>
              <p>Documents: {{ customerDetail.documents.length }}</p>
              <p>Sales Calls: {{ customerDetail.sales_calls.length }}</p>
              <p>Pumps Assigned: {{ customerDetail.pump_list.length }}</p>
            </b-colxx>
          </b-row>
        </div>
        <div v-if="deleteInProgress">
          <div class="d-flex justify-content-center">
            <p>Deleting Prospect...</p>
          </div>
        </div>
        <template #modal-footer>
          <div v-if="!deleteProspectComplete">
            <b-button
              class="btn btn-danger"
              :disabled="disableDeleteProspectButton"
              @click="deleteProspect()"
              >Please Confirm</b-button
            >
            <b-button
              class="btn btn-info"
              @click="
                () => {
                  showConfirmDeleteProspect = false;
                }
              "
              >Cancel</b-button
            >
          </div>
          <div v-if="deleteProspectComplete">
            <b-button class="btn btn-info" @click="closeDeleteProspect()"
              >Close</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";

import BaseCheckbox from "@/components/input/BaseCheckbox.vue";
import SalesCall from "@/views/customer/components/SalesCall.vue";
import Contact from "@/views/customer/components/Contact.vue";

// import { dateTimeNoOffsetNoSeconds } from "@/functions"
import { contactObj } from "@/constants/objects";
import { salesCallObj } from "@/constants/objects/salescall";
import { province } from "@/constants/province";
import { updateWithResponseData } from "@/functions/common";

import { saveSalesCall } from "@/functions/salescall";

import {
  newLocalTodayDateObject,
  dateTimeToLocal,
  dateTimeNoOffsetNoSeconds,
} from "@/functions/time";
import { toHandlers } from "@vue/runtime-dom";

export default {
  name: "CompanyDetail",
  components: {
    BaseCheckbox,
    SalesCall,
    Contact,
  },

  data() {
    return {
      // isReady: false,
      newLocalTodayDateObject,
      customerDetail: {
        contacts: [],
        sales_calls: [],
        invoices: [],
        shipto: [],
        pump_list: [],
        categories: [],
        documents: [],
        related_company_records: [],
      },
      province,
      sales_call_list_fields: [
        {
          label: "",
          key: "actions",
        },
        {
          label: "Contact",
          key: "contact_name",
          sortable: true,
        },
        {
          label: "Notes",
          key: "notes",
          thStyle: {
            width: "700px",
          },
        },
        {
          label: "Category",
          key: "category_name",
        },
        {
          label: "Created By",
          key: "created_by_name",
        },
        {
          label: "Call Date",
          key: "call_date",
          sortable: true,
        },
      ],
      ship_to_fields: [
        {
          label: "Display on Website",
          key: "action",
          sortable: "false",
        },
        {
          label: "Location Name",
          key: "saddrname",
          sortable: true,
        },
        {
          label: "Address",
          key: "sshipstrt1",
        },
        {
          label: "Address",
          key: "sshipstrt2",
        },
        {
          label: "City",
          key: "sshipcity",
        },
        {
          label: "Prov",
          key: "sshipprvst",
        },
        {
          label: "Country",
          key: "sshipcnty",
        },
        {
          label: "Postal Code",
          key: "sshippstzp",
        },
        {
          label: "Phone",
          key: "sshipphn1",
        },
      ],
      related_company_records_fields: [
        {
          label: "",
          key: "action",
          sortable: false,
        },
        {
          label: "Name",
          key: "customer_name",
        },
        {
          label: "Address",
          key: "address_1",
        },
        {
          label: "City",
          key: "city",
        },
        {
          label: "Prov",
          key: "province",
        },
        {
          label: "Postal Code",
          key: "postal_code",
        },
        {
          label: "Phome",
          key: "phone",
        },
      ],
      gettingCustomerInvoices: false,
      invoice_list: [],
      so_fields: [
        {
          label: "",
          key: "expand",
          sortable: false,
        },
        {
          label: "Date",
          key: "invoice_date",
          sortable: false,
        },
        {
          label: "Ref #",
          key: "ssource1",
        },
        {
          label: "Total",
          key: "invoice_total",
        },
      ],

      pump_list_fields: [
        {
          key: "action",
          label: "",
        },
        {
          key: "serial_no",
          label: "Serial Number",
        },
        {
          key: "pump_type_name",
          label: "Type",
        },
        {
          key: "is_leased_yn",
          label: "Leased",
        },
        {
          key: "purchase_date",
          label: "Purchased",
        },
      ],

      // These are for ship to address table
      current_page: 1,
      per_page: 10,
      total_rows: null,

      //These are for sales order table
      so_current_page: 1,
      so_per_page: 10,

      filterShipToAddress: null,
      filterSalesOrderText: "",

      salesTerritoryList: [],
      categoryList: [],

      saveInProgress: false,
      showAlert: false,
      alertVariant: "",
      alertMessage: "",

      companyTabIndex: 0,

      showContactModal: false,
      editContactYN: false,
      contactObj,
      editContact: {},
      saveContactComplete: false,

      editContactIndex: null,
      contact_list_fields: [
        {
          label: "",
          key: "actions",
          sortable: false,
        },
        {
          label: "Name",
          key: "full_name",
          sortable: true,
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "Phone",
          key: "phone",
        },
        {
          label: "Position",
          key: "position",
        },
      ],
      contactIsSaving: false,
      contactFormIsValid: false,

      showSalesCallModal: false,
      showSalesCallAlert: false,
      salesCallAlertMessage: "",
      salesCallAlertVariant: "",
      salesCallReadOnlyYN: null,

      salesCallObj,

      editSalesCall: {},
      editSalesCallYN: false,
      salesCallIsSaving: false,
      saveSalesCallComplete: false,
      viewSalesCall: false,

      customerPriceList: {},
      priceListModal: false,
      gettingPriceList: false,

      price_list_detail_fields: [
        {
          key: "item_name",
          label: "Name",
        },
        {
          key: "code",
          label: "SKU",
        },
        {
          key: "formatted_price",
          label: "Price",
        },
      ],

      filterItemName: "",
      filterOn: ["item_name"],

      newFile: {
        file: null,
        name: "",
        type: "",
        size: null,
        entity_type_class_name: "customerprofile",
      },

      viewImgSrc: "",
      viewDocumentModal: false,
      isUploading: false,

      documentUploadAlert: false,
      documentUploadAlertMessage: "",
      documentUploadAlertVariant: "",

      selectedDocumentId: null,
      selectedDocumentIndex: null,
      showConfirmDeleteDocument: false,

      document_list_fields: [
        {
          key: "actions",
          label: "",
        },
        {
          key: "file_name",
          label: "File Name",
        },
        {
          key: "file_size",
          label: "Size",
        },
        {
          key: "file_size",
          label: "Size",
        },
        {
          key: "file_type",
          label: "Type",
        },
        {
          key: "created_at",
          label: "Upload Date",
        },
        {
          key: "created_by_name",
          label: "Upload By",
        },
      ],

      showConvertProspectModal: false,
      findCustomerInSage: true,
      sageCustomerNumber: null,
      getSageCustomerForProspect: [],
      convertProspectAlertMessage: "",
      convertProspectAlertVariant: "",
      showConvertProspectAlert: false,
      disableConvertProspectButton: true,
      showConfirmConvertProspect: false,

      showConfirmDeleteProspect: false,
      deleteProspectAlertMessage: "",
      deleteProspectAlertVariant: "",
      showDeleteProspectAlert: false,
      deleteInProgress: false,
      deleteProspectComplete: false,

      prospectMergeType: "CONVERT_CUSTOMER",
      prospectMergeOptions: [
        { text: "Convert to Customer", value: "CONVERT_CUSTOMER" },
        { text: "Make Customer Location", value: "CUSTOMER_LOCATION" },
      ],
      convertCustomerComplete: null,
    };
  },

  watch: {
    companyTabIndex(newIndex, oldIndex) {
      if (newIndex !== 0) {
        this.closeSalesCall();
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["user", "permissions"]),
    ...mapGetters("common", ["showLoader"]),
    ...mapGetters("customer", ["customerId", "isProspect"]),

    showWebsiteAddressOptions() {
      if (!this.isProspect) {
        if (this.customerDetail.include_on_website_yn) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    soPageTotal() {
      var page_total = 1;
      page_total = Math.ceil(this.total_rows / this.per_page);
      return page_total;
    },

    filteredInvoiceList() {
      if (!this.isProspect) {
        let invoices = this.invoice_list;
        if (this.filterSalesOrderText !== "") {
          invoices = invoices.filter((obj) =>
            obj.ssource1
              .toLowerCase()
              .includes(this.filterSalesOrderText.toLowerCase())
          );
        }
        return invoices;
      } else {
        return null;
      }
    },

    hasRelatedCustomerRecords() {
      if (this.customerDetail.related_company_records.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    relatedRecordCompanyNameList() {
      if (this.customerDetail.related_company_records.length === 0) {
        return null;
      }
      let list = this.customerDetail.related_company_records;
      let company_name_list = [];
      for (var i = 0; i < list.length; i++) {
        company_name_list.push(list[i].customer_name);
      }
      return company_name_list;
    },

    filteredSalesTerritoryList() {
      let list = this.salesTerritoryList;
      let filteredList = list.filter((x) => x.inactive_yn === false);
      return filteredList;
    },

    showPriceListButton() {
      if (this.customerDetail.price_list_id !== null) {
        return true;
      } else {
        return false;
      }
    },

    saveButtonLabel() {
      if (this.saveInProgress) {
        return "Saving...";
      } else {
        return "Save";
      }
    },

    contactSaveButtonLabel() {
      if (this.contactIsSaving) {
        return "Saving...";
      } else {
        return "Save";
      }
    },

    customerLocations() {
      if (!this.isProspect) {
        let locations = this.customerDetail.shipto;
        return locations;
      } else {
        return null;
      }
    },

    customerType() {
      return this.customerDetail.customer_type;
    },

    disableInactiveSwitch() {
      if (
        this.customerDetail.customer_type === "PROSPECT" ||
        this.customerDetail.customer_type === "CUSTOMER_LOCATION"
      ) {
        return false;
      } else {
        return true;
      }
    },

    salesCallSaveButtonLabel() {
      if (this.salesCallIsSaving) {
        return "Saving...";
      } else {
        return "Save";
      }
    },

    salesCallFormIsValid() {
      if (
        this.editSalesCall.contact !== null &&
        this.editSalesCall.contact !== undefined &&
        this.editSalesCall.call_date !== null &&
        this.editSalesCall.call_date !== undefined &&
        this.editSalesCall.category !== null &&
        this.editSalesCall.category !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    },

    canEditCustomer() {
      if (this.permissions.includes("CanEditCustomer")) {
        return true;
      } else {
        return false;
      }
    },

    canViewSalesInvoices() {
      if (this.permissions.includes("CanViewSalesInvoices")) {
        return true;
      } else {
        return false;
      }
    },

    canCreateContact() {
      if (this.permissions.includes("CanCreateContact")) {
        return true;
      } else {
        return false;
      }
    },

    canViewContact() {
      if (this.permissions.includes("CanViewContact")) {
        return true;
      } else {
        return false;
      }
    },

    canCreateSalesCall() {
      if (this.permissions.includes("CanCreateSalesCall")) {
        return true;
      } else {
        return false;
      }
    },

    disableDeleteProspectButton() {
      if (this.customerDetail.pump_list.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    mergeCustomerWarningMessage() {
      if (this.prospectMergeType === "CONVERT_CUSTOMER") {
        return "Executing the merge will convert the prospect to a customer and merge the Sage customer record with the prospect record. This action is irreversible.";
      } else {
        return "Executing the merge will convert the prospect to a customer location. This action is irreversible.";
      }
    },
  },

  created() {},

  async mounted() {
    this.$store.dispatch("common/showLoader", true);
    // if (!this.isProspect) {
    await django_session
      .get("erp/customer/" + this.customerId + "/")
      .then((response) => {
        this.customerDetail = response.data;
        // console.log(this.invoice_list)
        document.title = this.customerDetail.customer_name + " Details";
      })
      .catch((error) => {
        console.log(error.response);
      });

    await django_session
      .get("erp/customer/category/list/")
      .then((response) => {
        this.categoryList = response.data.results;
      })
      .then(() => {
        if (this.customerDetail.categories.length > 0) {
          for (var i = 0; i < this.categoryList.length; i++) {
            let category_exists = this.customerDetail.categories.find(
              (obj) => obj.category_id === this.categoryList[i].category_id
            );
            if (category_exists) {
              Object.assign(this.categoryList[i], {
                checked: true,
              });
            } else {
              Object.assign(this.categoryList[i], {
                checked: false,
              });
            }
          }
        }
      });

    if (!this.isProspect) {
      await django_session
        .get("sage/invoice-list/", {
          params: {
            customer_id: this.customerDetail.sage_customer_id,
            page_size: this.per_page,
            page: 1,
          },
        })
        .then((response) => {
          this.invoice_list = response.data.results;
          this.total_rows = response.data.count;
        });
    }

    await django_session
      .get("erp/customer/territory/list/", {
        params: {
          page_size: 0,
        },
      })
      .then((response) => {
        this.salesTerritoryList = response.data;
      });

    this.$store.dispatch("common/showLoader", false);
  },

  methods: {
    updateCustomerTerritory() {
      let selected_territory = this.customerDetail.sales_territory_id;
      let territory = this.salesTerritoryList.find(
        (obj) => obj.territory_id === selected_territory
      );
      this.customerDetail.sales_rep_name = territory.sales_rep_name;
    },

    async getCustomerInvoices() {
      this.gettingCustomerInvoices = true;
      await django_session
        .get("sage/invoice-list/", {
          params: {
            customer_id: this.customerDetail.sage_customer_id,
            keyword: this.filterSalesOrderText,
            page_size: this.per_page,
            page: this.current_page,
          },
        })
        .then((response) => {
          this.invoice_list = response.data.results;
          this.total_rows = response.data.count;
          this.gettingCustomerInvoices = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.gettingCustomerInvoices = false;
        });
      this.$store.dispatch("common/showLoader", false);
    },

    saveCustomerProfile() {
      this.saveInProgress = true;
      django_session
        .patch(
          "erp/customer/" + this.customerDetail.erp_customer_id + "/",
          this.customerDetail
        )
        .then((response) => {
          console.log(response.data);
          this.customerDetail.erp_customer_id = response.data.erp_customer_id;
          this.customerDetail.categories = response.data.categories;
          this.customerDetail.sales_territory = response.data.sales_territory;
          this.customerDetail.retail_rep_name = response.data.retail_rep_name;
          this.customerDetail.institutional_rep_name =
            response.data.institutional_rep_name;
          this.customerDetail.include_on_website_yn =
            response.data.include_on_website_yn;
          this.customerDetail.is_active_yn = response.data.is_active_yn;
        })
        .then(() => {
          this.alertVariant = "success";
          this.alertMessage = "Save successful.";
          this.showAlert = true;
          this.saveInProgress = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.alertVariant = "warning";
          this.alertMessage = "Error during the save process.";
          this.showAlert = true;
          this.saveInProgress = false;
        });
    },

    async updateShipToAddress(item) {
      await django_session
        .patch("erp/customer/shipto/" + item.ship_to_address_id + "/", item)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async goRelatedCompanyDetail(item) {
      this.$store.dispatch("common/showLoader", true);
      this.$store.dispatch("customer/setCustomerId", item.erp_customer_id);
      await django_session
        .get("erp/customer/" + this.customerId + "/")
        .then((response) => {
          this.customerDetail = response.data;
          console.log(this.invoice_list);
          document.title = this.customerDetail.customer_name + " Details";
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$store.dispatch("common/showLoader", false);
    },

    convertProspect() {
      console.log("Convert Prospect");
      this.findCustomerInSage = true;
      this.sageCustomerNumber = null;
      this.getSageCustomerForProspect = [];
      this.convertProspectAlertMessage = "";
      this.convertProspectAlertVariant = "";
      this.showConvertProspectAlert = false;
      this.showConfirmConvertProspect = false;
      this.showConvertProspectModal = true;
    },

    async deleteProspect() {
      this.deleteInProgress = true;
      await django_session
        .delete("erp/customer/" + this.customerDetail.erp_customer_id + "/")
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("customer/setProspectFlag", false);
          this.deleteProspectAlertMessage = "Prospect deleted successfully.";
          this.deleteProspectAlertVariant = "success";
          this.showDeleteProspectAlert = true;
        })
        .catch((error) => {
          console.log(error.response);
          this.deleteProspectAlertMessage = error.response.data;
          this.deleteProspectAlertVariant = "danger";
          this.showDeleteProspectAlert = true;
        });
      this.deleteProspectComplete = true;
      this.deleteInProgress = false;
    },

    closeDeleteProspect() {
      this.showConfirmDeleteProspect = false;
      this.deleteProspectAlertMessage = "";
      this.deleteProspectAlertVariant = "";
      this.showDeleteProspectAlert = false;
      this.deleteProspectComplete = false;
      this.deleteError = false;
      this.$router.push({ name: "Company" });
    },

    async searchSageCustomerNumber() {
      // console.log("Search Sage Customer Number")
      await django_session
        .get("sage/customer-search/", {
          params: {
            sageCustomerNumber: this.sageCustomerNumber,
            page_size: 0,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.length === 1) {
            this.getSageCustomerForProspect = response.data[0];
            this.disableConvertProspectButton = false;
          }
          if (response.data.length > 1) {
            this.convertProspectAlertVariant = "warning";
            this.convertProspectAlertMessage =
              "More than one customer found with this number.";
            this.showConvertProspectAlert = true;
          }
          if (response.data.length === 0) {
            this.convertProspectAlertVariant = "warning";
            this.convertProspectAlertMessage =
              "No customer found with this number.";
            this.showConvertProspectAlert = true;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.findCustomerInSage = false;
    },

    cancelConvertProspect() {
      console.log("Cancel Convert Prospect");
      this.showConvertProspectModal = false;
      this.findCustomerInSage = true;
      this.sageCustomerNumber = null;
      this.getSageCustomerForProspect = [];
      this.convertProspectAlertMessage = "";
      this.convertProspectAlertVariant = "";
      this.showConfirmConvertProspect = false;
      this.showConvertProspectAlert = false;
      if (this.convertCustomerComplete === true) {
        this.$router.push({ name: "Company" });
      }
    },

    confirmConvertProspect() {
      this.showConfirmConvertProspect = true;
    },

    async convertProspectToCustomer() {
      // We are effectively merging records
      // Using the selected sage customer id, we update the contacts, sales calls and documents
      //
      console.log("Convert Prospect to Customer");
      this.saveInProgress = true;
      this.disableConvertProspectButton = true;
      await django_session
        .post("erp/customer/merge/", {
          sage_customer_id: this.getSageCustomerForProspect.lid,
          erp_customer_id: this.customerDetail.erp_customer_id,
          merge_type: this.prospectMergeType,
        })
        .then((response) => {
          this.saveInProgress = false;
          this.disableConvertProspectButton = false;
          this.convertCustomerComplete = true;
          console.log(response.data);
          // display success in modal
          // back to company list
        })
        .catch((error) => {
          console.log(error.response);
          // this.alertVariant = "warning"
          // this.alertMessage = "Error during the save process. "
          // this.showAlert = true
          this.saveInProgress = false;
          this.disableConvertProspectButton = false;
          this.convertCustomerComplete = false;
        });
    },

    createNewSalesCall() {
      this.showSalesCallModal = true;
      this.editSalesCallYN = false;
      this.viewSalesCall = true;
      this.editSalesCall = JSON.parse(JSON.stringify(salesCallObj));
      (this.editSalesCall.customer = this.customerDetail.erp_customer_id),
        (this.editSalesCall.customer_name = this.customerDetail.customer_name),
        (this.editSalesCall.user = this.user.user_id),
        (this.editSalesCall.user_name = this.user.full_name);
    },

    goSalesCallDetail(item, index) {
      this.editSalesCall = item;
      // convert item.call_date to local date and time without seconds for the input
      let call_date = dateTimeNoOffsetNoSeconds(item.call_date);
      this.editSalesCall.call_date =
        call_date.date_string + "T" + call_date.local_time_string;
      this.editSalesCallYN = true;
      this.viewSalesCall = true;
      this.showSalesCallModal = true;
    },

    updateCategoryCheckbox(item, index) {
      // Prospects are not assigned categories per Sandra instructions
      let findCategoryIndex = this.customerDetail.categories.findIndex(
        (cat) => cat.category_id === item.category_id
      );
      if (findCategoryIndex !== -1) {
        this.customerDetail.categories.splice(findCategoryIndex, 1);
      }
      if (findCategoryIndex === -1) {
        this.customerDetail.categories.push(item);
      }
    },

    createNewContact() {
      this.saveContactComplete = false;
      this.contactFormIsValid = false;
      this.editContactYN = false;
      this.editContact = JSON.parse(JSON.stringify(contactObj));
      // if (!this.isProspect) {
      //   this.editContact.sage_customer_id = this.customerDetail.lid
      //   this.editContact.customer_name = this.customerDetail.sname
      //   this.editContact.erp_customer_id = this.customerDetail.erp_customer_id
      // }
      // if (this.isProspect) {
      this.editContact.customer_name = this.customerDetail.customer_name;
      this.editContact.erp_customer_id = this.customerDetail.erp_customer_id;
      // this.editContact.sage_customer_id = this.customerDetail.sage_customer_id
      // this.editContact.sage_customer_location_id = this.customerDetail.sage_customer_location_id
      this.editContact.address_1 = this.customerDetail.address_1;
      this.editContact.address_2 = this.customerDetail.address_2;
      this.editContact.city = this.customerDetail.city;
      this.editContact.province = this.customerDetail.province;
      this.editContact.postal_code = this.customerDetail.postal_code;
      // }
      this.showContactModal = true;
    },

    updateContact(item, index) {
      // console.log("Update Contact")
      this.saveContactComplete = false;
      this.editContactYN = true;
      this.editContact = item;
      this.editContactIndex = index;
      this.$refs.__validateContactForm.validate().then((e) => {
        // console.log("Valid Contact Form", e)
        this.contactFormIsValid = e;
        this.showContactModal = true;
      });
      // this.showContactModal = true
    },

    async deleteContact(e) {
      this.contactIsSaving = true;
      let contactIndex = this.customerDetail.contacts.findIndex(
        (x) => x.contact_id === e.contact_id
      );
      await django_session
        .delete("erp/customer/contact/" + e.contact_id + "/")
        .then((response) => {
          console.log(response);
          this.contactIsSaving = false;
          this.customerDetail.contacts.splice(contactIndex, 1);
          this.$refs.__contactDetail.contactAlertVariant = "success";
          this.$refs.__contactDetail.contactAlertMessage = "Contact deleted.";
          this.$refs.__contactDetail.showContactAlert = true;
        })
        .catch((error) => {
          console.log(error);
          this.contactIsSaving = false;
          this.$refs.__contactDetail.contactAlertVariant = "danger";
          this.$refs.__contactDetail.contactAlertMessage =
            "Error deleting contact. " + error.response.data;
          this.$refs.__contactDetail.showContactAlert = true;
        });
    },

    updateContactData(event) {
      // console.log(event);
      this.editContact = event;
    },

    closeContact() {
      this.showContactModal = false;
      this.editContactYN = false;
      this.editContactIndex = null;
      // this.editContact = JSON.parse(JSON.stringify(contactObj));
    },

    async validateContactForm() {
      this.$refs.__validateContactForm.validate().then((e) => {
        console.log("Valid Contact Form", e);
        this.contactFormIsValid = e;
        if (this.contactFormIsValid) {
          this.saveContact();
        }
      });
    },

    async saveContact() {
      this.contactIsSaving = true;
      if (this.editContactYN === false) {
        await django_session
          .post("erp/customer/contact/create/", {
            first_name: this.editContact.first_name,
            last_name: this.editContact.last_name,
            email: this.editContact.email,
            phone: this.editContact.phone,
            position: this.editContact.position,
            notes: this.editContact.notes,
            is_active_yn: this.editContact.is_active_yn,
            // sage_customer_id: this.editContact.sage_customer_id,
            // sage_customer_location_id: this.editContact.sage_customer_location_id,
            erp_customer_id: this.editContact.erp_customer_id,
            address_1: this.editContact.address_1,
            address_2: this.editContact.address_2,
            city: this.editContact.city,
            province: this.editContact.province,
            postal_code: this.editContact.postal_code,
            created_by: this.user.user_id,
            updated_by: this.user.user_id,
          })
          .then((response) => {
            this.editContact = response.data;
            this.customerDetail.contacts.push(response.data);
            this.$refs.__contactDetail.contactAlertMessage = "Contact Created";
            this.$refs.__contactDetail.contactAlertVariant = "success";
            this.$refs.__contactDetail.showContactAlert = true;
            this.saveContactComplete = true;
            this.contactIsSaving = false;
          })
          .catch((error) => {
            console.log(error.response);
            this.$refs.__contactDetail.contactAlertMessage =
              "Server Error. Please contact the developer.";
            this.$refs.__contactDetail.contactAlertVariant = "danger";
            this.$refs.__contactDetail.showContactAlert = true;
            this.saveContactComplete = true;
            this.contactIsSaving = false;
          });
        this.saveContactComplete = true;
        this.contactIsSaving = false;
      }
      if (this.editContactYN === true) {
        await django_session
          .patch("erp/customer/contact/" + this.editContact.contact_id + "/", {
            first_name: this.editContact.first_name,
            last_name: this.editContact.last_name,
            email: this.editContact.email,
            phone: this.editContact.phone,
            position: this.editContact.position,
            notes: this.editContact.notes,
            is_active_yn: this.editContact.is_active_yn,
            // sage_customer_id: this.editContact.sage_customer_id,
            // sage_customer_location_id: this.editContact.sage_customer_location_id,
            erp_customer_id: this.editContact.erp_customer_id,
            address_1: this.editContact.address_1,
            address_2: this.editContact.address_2,
            city: this.editContact.city,
            province: this.editContact.province,
            postal_code: this.editContact.postal_code,
            updated_by: this.user.user_id,
          })
          .then((response) => {
            let responseData = updateWithResponseData(
              this.editContact,
              this.customerDetail.contacts,
              this.editContactIndex,
              response.data,
              false
            );
            this.editContact = responseData.updatedObject;
            this.customerDetail.contacts = responseData.updatedList;
            this.$refs.__contactDetail.contactAlertMessage = "Contact Updated";
            this.$refs.__contactDetail.contactAlertVariant = "success";
            this.$refs.__contactDetail.showContactAlert = true;
            this.saveContactComplete = true;
            this.contactIsSaving = false;
          })
          .catch((error) => {
            console.log(error.response);
            this.$refs.__contactDetail.contactAlert1Message =
              "Server Error. Please contact the developer.";
            this.$refs.__contactDetail.contactAlertVariant = "danger";
            this.$refs.__contactDetail.showContactAlert = true;
            this.saveContactComplete = true;
            this.contactIsSaving = false;
          });
        this.saveContactComplete = true;
        this.contactIsSaving = false;
      }
    },

    async openCreateSalesCall(item, index) {
      this.saveSalesCallComplete = false;
      this.editSalesCallYN = true;
      this.salesCallReadOnlyYN = false;
      this.viewSalesCall = false;
      this.editSalesCall = JSON.parse(JSON.stringify(salesCallObj));
      this.editSalesCall.call_date = newLocalTodayDateObject();

      this.editSalesCall.is_new_contact_yn = false;
      this.editSalesCall.contact_name = item.full_name;
      this.editSalesCall.contact = item.contact_id;
      this.editSalesCall.user_name = this.user.full_name;
      this.editSalesCall.user = this.user.user_id;
      this.editSalesCall.activity_assign_to = this.user.user_id;
      this.editSalesCall.customer = this.customerDetail.erp_customer_id;
      this.editSalesCall.customer_name = this.customerDetail.customer_name;
      this.editSalesCallIndex = null;
      this.showSalesCallModal = true;
      this.companyTabIndex = 0;
    },

    updateSalesCall(event) {
      this.editSalesCall = event;
    },

    closeSalesCall() {
      this.showSalesCallModal = false;
      this.editSalesCallYN = false;
      this.showSalesCallAlert = false;
      this.editSalesCall = JSON.parse(JSON.stringify(salesCallObj));
    },

    async salesCallSave() {
      this.salesCallIsSaving = true;

      let saveSalesCallRecord = await saveSalesCall(this.editSalesCall, false);
      console.log("Wait for Function", saveSalesCallRecord);

      if (saveSalesCallRecord.status === 200) {
        this.$refs.__salesCallDetail.salesCallAlertMessage =
          "Sales call information updated.";
        this.$refs.__salesCallDetail.salesCallAlertVariant = "success";
        this.$refs.__salesCallDetail.showSalesCallAlert = true;
        this.salesCallIsSaving = false;
        this.saveSalesCallComplete = true;

        let responseData = updateWithResponseData(
          this.editSalesCall,
          null,
          null,
          saveSalesCallRecord.data,
          true
        );

        this.editSalesCall = responseData.updatedObject;
        this.customerDetail.sales_calls.unshift(responseData.updatedObject);
        this.saveSalesCallComplete = true;
        this.salesCallIsSaving = false;
      } else {
        this.$refs.__salesCallDetail.salesCallAlertMessage =
          "Server Error. Please contact the developer.";
        this.$refs.__salesCallDetail.salesCallAlertVariant = "danger";
        this.$refs.__salesCallDetail.showSalesCallAlert = true;
        this.salesCallIsSaving = false;
        this.saveSalesCallComplete = true;
      }
    },

    async showPriceList() {
      this.priceListModal = true;
      this.gettingPriceList = true;
      await django_session
        .get("sage/price-list/" + this.customerDetail.price_list_id + "/")
        .then((response) => {
          this.customerPriceList = response.data;
          this.gettingPriceList = false;
        });
    },

    getFileParams(event) {
      // https://github.com/bootstrap-vue/bootstrap-vue/issues/4134
      // The browse and drop events return different object structures.

      if (event.type === "drop") {
        var dropfile = event.dataTransfer.files[0];
        console.log(dropfile);
        this.newFile.name = dropfile.name;
        this.newFile.size = dropfile.size;
        this.newFile.type = dropfile.type;
      }
      if (event.type === "change") {
        var file = document.getElementById("upload-file");
        console.log(file.files[0]);
        this.newFile.name = file.files[0].name;
        this.newFile.size = file.files[0].size;
        this.newFile.type = file.files[0].type;
      }
    },

    async uploadDocument() {
      this.isUploading = true;
      console.log("Upload File");
      let data = new FormData();
      data.append("file", this.newFile.file);
      data.append("file_name", this.newFile.name);
      data.append("file_type", this.newFile.type);
      data.append("file_size", this.newFile.size);
      data.append(
        "entity_type_class_name",
        this.newFile.entity_type_class_name
      );
      data.append("owning_entity_id", this.customerDetail.erp_customer_id);
      data.append("created_by", this.user.user_id);
      data.append("modified_by", this.user.user_id);
      await django_session
        .post("erp/common/document/create/", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data)
          // let fileUrl = response.data.
          this.customerDetail.documents.unshift(response.data);
          this.documentUploadAlert = true;
          this.documentUploadAlertMessage = "Document uploaded successfully.";
          this.documentUploadAlertVariant = "success";
          (this.newFile.file = null),
            (this.newFile.name = ""),
            (this.newFile.type = ""),
            (this.newFile.size = "");
        })
        .catch((error) => {
          this.documentUploadAlert = true;
          this.documentUploadAlertMessage =
            "Error uploading document" + error.response.data;
          this.documentUploadAlertVariant = "danger";
        });
      this.selectedDocument = null;
      this.deleteDocumentIndex = null;
      this.showConfirmDeleteDocument = false;
      this.isUploading = false;
    },

    viewDocument(item) {
      console.log(item);
      this.viewImgSrc = item.file;
      if (item.file_type === "image/jpeg" || item.file_type === "image/png") {
        this.viewDocumentModal = true;
      } else if (item.file_type === "application/pdf") {
        window.open(this.viewImgSrc);
      } else {
        window.open(this.viewImgSrc);
      }
    },

    confirmDeleteDocument(item, index) {
      this.selectedDocumentId = item.document_id;
      this.selectedDocumentIndex = index;
      this.showConfirmDeleteDocument = true;
    },

    deleteDocument(item, index) {
      console.log(item, index);
      django_session
        .delete("erp/common/document/delete/" + this.selectedDocumentId + "/")
        .then((response) => {
          console.log(response.data);
          this.selectedDocument = null;
          this.selectedDocumentIndex = null;
          this.customerDetail.documents.splice(index, 1);
          this.documentUploadAlertMessage = "Document deleted successfully.";
          this.documentUploadAlertVariant = "success";
          this.documentUploadAlert = true;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.documentUploadAlertMessage =
            "There was a problem deleting the file.";
          this.documentUploadAlertVariant = "warning";
          this.documentUploadAlert = true;
        });
    },

    goPumpDetail(item) {
      this.$store.dispatch("pump/setPumpId", item.pump_id);
      this.$store.dispatch("pump/setPumpObj", item);
      this.$store.dispatch("pump/setEditPumpFromDepotFlat", true);
      this.$router.push({ name: "PumpHome" });
    },
  },
};
</script>

<style lang="scss" scoped>
#sales-line-item-table {
  table-layout: fixed;
  width: 100%;
}

.fit-container {
  min-width: 100vw;
  min-height: 100vh;
}

.cursor-pointer:hover {
  cursor: pointer;
  color: rebeccapurple;
}

td {
  overflow: hidden;
}
.table {
  border-collapse: collapse;
  width: 100%;
}

.table-header,
.inner-table-header {
  background-color: #f2f2f2;
}

.table-header-cell,
.inner-table-header-cell {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-body .table-row:nth-child(even),
.inner-table-body .inner-table-row:nth-child(even) {
  background-color: #f2f2f2;
}

.table-cell,
.inner-table-cell {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.invoice-detail-row {
  background-color: #fff;
  border-top: 2px solid #ddd;
}

.inner-table {
  width: 100%;
  margin-top: 10px;
}
</style>
