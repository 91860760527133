export const salesCallObj = {
  is_new_contact_yn: null,
  call_date: null,
  call_time: null,
  category: null,
  category_name: "",
  contact: null,
  contact_email: "",
  contact_phone: "",
  created_at: "",
  created_by: null,
  created_by_name: "",
  customer: null,
  customer_name: "",
  modified_at: "",
  modified_by: null,
  modified_by_name: "",
  notes: "",
  sales_call_id: null,
  user: null,
  user_name: "",
  outlook_to_do_list: "",
  activity_follow_up_yn: false,
  activity_reminder_date: "",
  activity_due_date: "",
  activity_task_subject: "",
  activity_follow_up_note: "",
  activity_assign_to: null,
  activity_create_outlook_task_yn: false,
}
