export const updateWithResponseData = function(object, list, index, data, object_only) {

  for (let key in data) {
    if (object.hasOwnProperty(key)) {
      object[key] = data[key];
    }
    if (object_only === false) { 
      if (list[index].hasOwnProperty(key)) {
        list[index][key] = data[key];
      }
    }
    if (object_only === true) {
      list = null
    }
  }
  // console.log(object, list)
  return { updatedObject: object, updatedList: list}
}

