// Takes a date, time value from the database (stored as UTC) and converts to the users local time zone.
export const dateTimeToLocal = function (value) {
  console.log(value);
  var year = new Date(value).getFullYear();
  var month = new Date(value).getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = new Date(value).getDate();
  if (day < 10) {
    day = "0" + day;
  }

  var date_string = year + "-" + month + "-" + day;

  var timezone_offset = new Date(value).getTimezoneOffset();
  var timezone_offset_hours = timezone_offset / 60;
  var get_hours = new Date(value).getHours();
  var local_hours = get_hours - timezone_offset_hours;
  var utc_time = new Date(value);
  var local_time = new Date(utc_time).setHours(local_hours);
  var local_time_string = new Date(local_time).toLocaleTimeString();

  return { date_string, local_time_string };
};

// Takes a date & time value from an input (e.g. create sales call), extracts the date and time values to display as
// separate values with the seconds removed from the data.

export const dateTimeNoOffsetNoSeconds = function (value) {
  var year = new Date(value).getFullYear();
  var month = new Date(value).getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = new Date(value).getDate();
  if (day < 10) {
    day = "0" + day;
  }

  var date_string = year + "-" + month + "-" + day;

  var get_hours = new Date(value).getHours();
  if (get_hours < 10) {
    get_hours = "0" + get_hours;
  }
  var get_minutes = new Date(value).getMinutes();
  if (get_minutes < 10) {
    get_minutes = "0" + get_minutes;
  }
  var local_time_string = get_hours + ":" + get_minutes;
  return { date_string, local_time_string };
};

export const newLocalTodayDateObject = function () {
  var tzoffset = new Date().getTimezoneOffset() * 60000
  // Get the current time
  var currentTime = new Date(Date.now() - tzoffset)

  // Round the minutes to the nearest multiple of 15
  var roundedMinutes = Math.round(currentTime.getMinutes() / 15) * 15

  // Set the rounded minutes to the current time
  currentTime.setMinutes(roundedMinutes)

  // Convert the rounded time to a string in ISO 8601 format
  var roundedISOTime = currentTime.toISOString().replace(/:[^:]*$/, "")

  console.log(roundedISOTime)

  return roundedISOTime
};
