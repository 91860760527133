<template>
	<div v-if="!showLoader">
		<m-page-title title="Customers" />
		<b-card>
			<b-row class="align-items-center">
				<b-colxx
					md="6"
					sm="12"
					><b-form
						id="customer-search-main"
						@submit.prevent="getCustomerList"
					>
						<b-form-group class="mb-0">
							<b-input-group class="">
								<div class="input-group-prepend">
									<span class="input-group-text"
										><i class="fas fa-search"></i
									></span>
								</div>
								<b-form-input
									v-model="searchCustomerName"
									placeholder="Search by customer name, city"
									type="text"
								>
								</b-form-input>
							</b-input-group>
						</b-form-group>
					</b-form>
				</b-colxx>
				<!-- <div v-if="permissions.includes('CanCreateProspect')"> -->
				<b-colxx
					md="6"
					sm="12"
				>
					<b-button
						v-if="permissions.includes('CanCreateProspect')"
						class="bg-purple text-white float-right"
						@click="openProspectModal()"
						><i class="fas fa-circle-plus text-white mr-1" />New
						Prospect</b-button
					>
				</b-colxx>
				<!-- </div> -->
			</b-row>
			<div class="row mt-3 align-items-center">
				<b-colxx
					md="2"
					sm="12"
				>
					<b-form-group
						id="client-type-fieldset"
						label="Client Type"
						label-for="client-type-select"
						class="field-label-form-group"
					>
						<b-form-select
							id="client-type-select"
							v-model="selectCustomerCategory"
							:options="categoryList"
							value-field="category_id"
							text-field="customer_category_name"
							multiple
							:select-size="2"
						></b-form-select>
						<b-form-text>Hold 'Ctrl' to select multiple.</b-form-text>
					</b-form-group>
				</b-colxx>
				<b-colxx
					v-if="userCanViewAllCustomers"
					md="2"
					sm="12"
					><b-form-group
						id="salesrep-type-fieldset"
						label="Sales Rep"
						label-for="salesrep-select"
						class="field-label-form-group"
					>
						<b-form-select
							id="salesrep-select"
							v-model="selectSalesRep"
							:options="salesRepList"
							value-field="user_id"
							text-field="full_name"
						></b-form-select> </b-form-group
				></b-colxx>
				<b-colxx
					md="2"
					sm="12"
					><b-form-group
						id="territory-type-fieldset"
						label="Territory"
						label-for="territory-select"
						class="field-label-form-group"
					>
						<b-form-select
							id="territory-select"
							v-model="selectCustomerTerritory"
							:options="filteredTerritoryList"
							value-field="territory_id"
							text-field="territory_name"
						></b-form-select> </b-form-group
				></b-colxx>
				<b-colxx
					v-if="userCanViewAllCustomers"
					md="2"
					sm="12"
					><b-form-group
						id="prov-type-fieldset"
						label="Province"
						label-for="prov-select"
						class="field-label-form-group"
					>
						<b-form-select
							id="prov-select"
							v-model="selectCustomerProvince"
							:options="province"
						></b-form-select> </b-form-group
				></b-colxx>
				<div class="flex-column flex-shrink">
					<b-button
						variant="success"
						@click="getCustomerList()"
						>Search</b-button
					>
					<b-button
						variant="info"
						@click="clearSearchFilters()"
						>Clear</b-button
					>
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col flex-column flex-shrink-1">
					<b-form-group
						id="compnay-active-fieldset"
						label="Hide Inactive"
						label-for="hide-company-inactive-yn"
						class="field-label-form-group"
					>
						<b-form-checkbox
							id="hide-company-inactive-yn"
							v-model="hideInactiveYN"
						></b-form-checkbox>
					</b-form-group>
				</div>
			</div>
		</b-card>

		<!--  -->
		<!-- Customer List Table -->
		<!--  -->

		<div class="my-4">
			<b-table
				id="customer-table"
				ref="__customerTable"
				head-variant="dark"
				:items="customerList"
				:fields="customer_list_fields"
				:current-page="current_page"
				per-page="0"
			>
				<template #table-colgroup="scope">
					<col
						v-for="field in scope.fields"
						:key="field.key"
						:style="{
							width: field.key === 'view' ? '10px' : '',
						}"
					/>
				</template>
				<template #cell(view)="row">
					<i
						class="fas fa-eye text-default icon-pointer"
						@click="goCustomerDetail(row.item, row.index)"
					/>
				</template>
				<template #cell(customer_type_flag)="row">
					<i
						v-if="row.item.customer_type === 'PROSPECT'"
						class="fas fa-star text-purple ml-1"
					/>
					<i
						v-if="row.item.customer_type === 'CUSTOMER'"
						class="fas fa-building text-orange"
					/>
					<i
						v-if="row.item.customer_type === 'CUSTOMER_LOCATION'"
						class="fas fa-home text-green"
					/>
				</template>
				<template #cell(customer)="row">
					<span class="customer-name">
						{{ row.item.customer_name }}
						<span
							v-if="row.item.inactive_yn"
							class="ml-1 d-inline"
							:style="{ color: 'red' }"
						>
							<i class="fas fa-do-not-enter"></i>
						</span>
					</span>
					<span class="">{{ row.item.address_1 }}</span>
					<span class="">{{ row.item.city }}, {{ row.item.province }}</span>
					<span class="">{{ row.item.phone }}</span>
					<div
						v-for="item in row.item.categories"
						:key="item.category_id"
						class="badge text-white p-2 mt-1 mr-1"
						:style="{ 'background-color': `${item.category_colour}` }"
					>
						{{ item.customer_category_name }}
					</div>
				</template>
				<template #cell(sales_rep)="row">
					<span>Territory: {{ row.item.sales_territory }}</span>
					<span>Sales Rep: {{ row.item.sales_rep_name }}</span>
				</template>
			</b-table>
			<b-pagination
				v-model="current_page"
				:total-rows="total_rows"
				:per-page="per_page"
				:number-of-pages="pageTotal"
				size="sm"
				class="my-0"
				aria-controls="customer-table"
				@input="getCustomerList(current_page)"
			></b-pagination>
		</div>

		<!--  -->
		<!-- Create Prospect Modal -->
		<!--  -->
		<div id="create-prospect-modal-div">
			<validation-observer
				ref="__validateProspectForm"
				v-slot="observer"
			>
				<b-modal
					id="prospect-modal"
					v-model="showCreateProspectModal"
					title="New Prospect"
					centered
					no-close-on-esc
					no-close-on-backdrop
					hide-header-close
					scrollable
					header-bg-variant="info"
					header-text-variant="white"
					size="lg"
				>
					<b-row>
						<b-colxx
							md="12"
							sm="12"
						>
							<b-row class="my-1">
								<b-alert
									v-model="showProspectAlert"
									:variant="prospectAlertVariant"
									class="w-100"
									dismissible
									>{{ prospectAlertMessage }}</b-alert
								>
								<b-alert
									v-model="showDuplicateAlert"
									variant="warning"
									class="w-100"
									>We found companies with the same name. Please double check
									the company list before proceeding.</b-alert
								>
							</b-row>
						</b-colxx>
					</b-row>
					<div
						id="prospect-detail"
						role="tablist"
					>
						<b-form-row class="my-2">
							<b-colxx
								md="12"
								sm="12"
							>
								<m-text-input
									id="company-name"
									label="Company Name"
									:rules="{ required: true, max: 125 }"
									:value="newProspect.customer_name"
									:alternative="false"
									:group="false"
									name="Company"
									@update-value="
										(input) => {
											newProspect.customer_name = input;
										}
									"
									@change="searchProspectName()"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="address-1"
									label="Address 1"
									:rules="{ required: false, max: 100 }"
									:value="newProspect.address_1"
									:alternative="false"
									:group="false"
									name="Address 1"
									@update-value="
										(input) => {
											newProspect.address_1 = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="address-2"
									label="Address 2"
									:rules="{ required: false, max: 100 }"
									:value="newProspect.address_2"
									:alternative="false"
									:group="false"
									name="Address 2"
									@update-value="
										(input) => {
											newProspect.address_2 = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="city"
									label="City"
									:rules="{ required: true, max: 75 }"
									:value="newProspect.city"
									:alternative="false"
									:group="false"
									name="City"
									@update-value="
										(input) => {
											newProspect.city = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="3"
								sm="12"
								><b-form-group
									id="prov-type-fieldset"
									label="Province"
									label-for="-prospect-prov-select"
									class="field-label-form-group mt-2"
								>
									<b-form-select
										id="prospect-prov-select"
										v-model="newProspect.province"
										:options="province"
									></b-form-select> </b-form-group
							></b-colxx>
							<b-colxx
								md="3"
								sm="12"
							>
								<m-text-input
									id="postal-code"
									label="Postal Code"
									:rules="{ required: false, max: 7 }"
									:value="newProspect.postal_code"
									:alternative="false"
									:group="false"
									name="Postal Code"
									@update-value="
										(input) => {
											newProspect.postal_code = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="company-phone"
									label="Company Phone"
									type="phone"
									:rules="{ required: false, max: 50 }"
									:value="newProspect.company_phone"
									:alternative="false"
									:group="false"
									name="Company Phone"
									@update-value="
										(input) => {
											newProspect.company_phone = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="company-website"
									label="Website"
									:rules="{ required: false, max: 100 }"
									:value="newProspect.website"
									:alternative="false"
									:group="false"
									name="Website"
									@update-value="
										(input) => {
											newProspect.website = input;
										}
									"
								/>
							</b-colxx>
						</b-form-row>
						<b-form-row>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="first-name"
									label="First Name"
									:rules="{ max: 50 }"
									:value="newProspect.first_name"
									:alternative="false"
									:group="false"
									name="First Name"
									@update-value="
										(input) => {
											newProspect.first_name = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="last-name"
									label="Last Name"
									:rules="{ max: 75 }"
									:value="newProspect.last_name"
									:alternative="false"
									:group="false"
									name="Last Name"
									@update-value="
										(input) => {
											newProspect.last_name = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx cols="12">
								<m-text-input
									id="email"
									label="Email"
									:rules="{ email: true }"
									:value="newProspect.email"
									:alternative="false"
									:group="false"
									name="Email"
									@update-value="
										(input) => {
											newProspect.email = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="phone"
									label="Phone"
									type="phone"
									:rules="{ max: 14 }"
									:value="newProspect.phone"
									:alternative="false"
									:group="false"
									name="Phone"
									@update-value="
										(input) => {
											newProspect.phone = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx
								md="6"
								sm="12"
							>
								<m-text-input
									id="position"
									label="Position"
									:rules="{ max: 50 }"
									:value="newProspect.position"
									:alternative="false"
									:group="false"
									name="Position"
									@update-value="
										(input) => {
											newProspect.position = input;
										}
									"
								/>
							</b-colxx>
							<b-colxx cols="12">
								<m-text-area
									id="contact-note"
									:value="newProspect.notes"
									name="Notes"
									:rows="7"
									:rules="{ max: 4000 }"
									label="Notes"
									:alternative="false"
									:group="false"
									@update-value="(input) => (newProspect.notes = input)"
								/>
							</b-colxx>
						</b-form-row>
						<b-form-row>
							<b-colxx
								md="4"
								sm="12"
							>
								<b-form-group
									id="territory-type-fieldset"
									label="Territory"
									label-for="territory-select"
									class="field-label-form-group"
								>
									<b-form-select
										id="territory-select"
										v-model="newProspect.territory"
										:options="filteredTerritoryList"
										value-field="territory_id"
										text-field="territory_name"
									></b-form-select>
								</b-form-group>
							</b-colxx>
							<b-colxx
								md="2"
								sm="2"
							>
								<b-form-group
									label="Assign Prospect"
									class="field-label-form-group mb-4"
								>
									<b-form-checkbox
										id="assign-prospect-yn"
										v-model="assignProspectYN"
										@change="
											() => {
												assignProspectYN != assignProspectYN;
											}
										"
									/>
								</b-form-group>
							</b-colxx>
							<b-colxx
								v-show="assignProspectYN"
								md="4"
								sm="12"
							>
								<b-form-group
									id="sales-rep-list"
									label="Assign To Rep"
									label-for="sales-rep-select"
									class="field-label-form-group"
								>
									<b-form-select
										id="sales-rep-select"
										v-model="newProspect.assign_to_sales_rep"
										:options="salesRepList"
										:rules="{ required: assignProspectYN }"
										value-field="user_id"
										text-field="full_name"
									></b-form-select>
								</b-form-group>
							</b-colxx>
						</b-form-row>
					</div>

					<template #modal-footer="{}">
						<b-button
							v-if="!prospectSaveComplete"
							class="mr-2"
							variant="info"
							:disabled="prospectIsSaving || observer.invalid"
							@click="validateProspect()"
						>
							{{ prospectSaveButtonLabel }}
						</b-button>
						<b-button
							variant="warning"
							:disabled="prospectIsSaving"
							@click="closeProspect()"
							>Close</b-button
						>
					</template>
				</b-modal>
			</validation-observer>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from "vuex";
	import django_session from "@/api/django_session";

	import { province } from "@/constants/province";
	import { prospectObj } from "@/constants/objects";

	export default {
		name: "CompanyHome",
		components: {},

		data() {
			return {
				customerList: [],
				customer_list_fields: [
					{
						label: "",
						key: "view",
						thClass: "narrow-column",
					},
					{
						label: "",
						key: "customer_type_flag",
						thClass: "narrow-column",
					},
					{
						label: "Customer",
						key: "customer",
					},
					{
						label: "Sales Rep",
						key: "sales_rep",
					},
					{
						label: "Price List",
						key: "price_list_name",
					},
					{
						label: "Orders (l365)",
						key: "sales_order_count",
					},
					{
						label: "Sales (l365)",
						key: "sales_order_total",
					},
					{
						label: "Last Call",
						key: "last_call",
					},
					{
						label: "",
						key: "actions",
					},
				],
				province,
				current_page: null,
				per_page: 10,
				total_rows: null,
				prev_page_link: "",
				next_page_link: "",
				// error_message: "",
				hideInactiveYN: true,

				searchCustomerName: "",
				selectCustomerCategory: [],
				selectCustomerTerritory: null,
				selectCustomerProvince: "",
				selectSalesRep: null,

				categoryList: [],
				territoryList: [],
				salesRepList: [],

				showCreateProspectModal: false,
				showProspectAlert: false,
				prospectAlertMessage: "",
				prospectAlertVariant: "",
				prospectIsSaving: false,
				newProspect: {},
				searchingForDuplicateCompanies: false,
				duplicateProspectList: [],
				duplicateProspectListLength: 0,
				prospectFormIsValid: false,

				assignProspectYN: false,
				prospectSaveComplete: false,
			};
		},

		computed: {
			...mapGetters("auth", ["user", "roles", "permissions"]),
			...mapGetters("common", ["currentPage", "previousView", "showLoader"]),

			pageTotal() {
				var page_total = 1;
				page_total = Math.ceil(this.total_rows / this.per_page);
				return page_total;
			},

			filteredTerritoryList() {
				let list = this.territoryList
        if (this.roles.includes("SLS_REP")) {
          return list.filter((territory) => territory.sales_rep_id === this.user.user_id)
        }
        return list
			},

			userIsSalesRep() {
				if (this.roles.includes("SLS_REP")) {
					return true;
				} else {
					return false;
				}
			},

			userCanViewAllCustomers() {
				if (this.permissions.includes("CanViewAllCustomers")) {
					return true;
				} else {
					return false;
				}
			},

			userCanViewOwnCustomers() {
				if (this.permissions.includes("CanViewOwnCustomers")) {
					return true;
				} else {
					return false;
				}
			},

			notifySalesRep() {
				if (this.newProspect.assign_to_sales_rep !== this.user.user_id) {
					return true;
				} else {
					return false;
				}
			},

			newProspectHasContact() {
				if (
					this.newProspect.first_name !== "" ||
					this.newProspect.last_name !== ""
				) {
					return true;
				} else {
					return false;
				}
			},

			// disableProspectSaveButton() {
			//   if (this.newProspect.customer_name === "") {
			//     return true;
			//   }
			//   if (
			//     this.newProspectHasContact &&
			//     (this.newProspect.first_name === "" ||
			//       this.newProspect.last_name === "")
			//   ) {
			//     return true;
			//   }
			//   if (this.showDuplicateAlert) {
			//     return true;
			//   }
			//   return false;
			// },

			prospectSaveButtonLabel() {
				if (this.prospectIsSaving) {
					return "Saving...";
				}
				return "Save";
			},

			showDuplicateAlert() {
				if (this.duplicateProspectListLength === 0) {
					return false;
				}
				return true;
			},
		},

		created() {},

		async mounted() {
			this.$store.dispatch("common/showLoader", true);
			document.title = "Customer List";
			await django_session
				.get("erp/customer/category/list/")
				.then((response) => {
					this.categoryList = response.data.results;
				});
			await django_session
				.get("erp/customer/territory/list/", {
					params: {
						page_size: 0,
					},
				})
				.then((response) => {
					this.territoryList = response.data;
				});
			await django_session
				.get("erp/user/list-sales-rep/", {
					params: {
						page_size: 0,
					},
				})
				.then((response) => {
					this.salesRepList = response.data;
				});
			if (this.userIsSalesRep) {
				this.selectSalesRep = this.user.user_id;
			}

			if (this.previousView.from_route === this.$route.path) {
				console.log("Load Previous View Data");
				await this.$store.dispatch("common/getPreviousView").then(() => {
					this.current_page = this.previousView.current_page;
					this.searchCustomerName = this.previousView.searchCustomerName;
					this.selectCustomerCategory =
						this.previousView.selectCustomerCategory;
					this.selectCustomerProvince =
						this.previousView.selectCustomerProvince;
					this.selectSalesRep = this.previousView.selectSalesRep;
					this.selectCustomerTerritory =
						this.previousView.selectCustomerTerritory;
				});
			}
			console.log("Done Get Previous View");
			await this.getCustomerList();
			// this.$store.dispatch("common/showLoader", false);
		},

		methods: {
			async getCustomerList() {
				this.$store.dispatch("common/showLoader", true);
				await django_session
					.get("erp/customer/list/", {
						params: {
							page: this.current_page,
							keyword: this.searchCustomerName,
							category: this.selectCustomerCategory,
							territory: this.selectCustomerTerritory,
							province: this.selectCustomerProvince,
							sales_rep: this.selectSalesRep,
							hide_inactive_yn: this.hideInactiveYN,
						},
					})
					.then((response) => {
						console.log("Return customer list");
						this.total_rows = response.data.count;
						this.prev_page_link = response.data.links.previous;
						this.next_page_link = response.data.links.next;
						this.current_page = response.data.page;
						this.customerList = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
				this.$store.dispatch("common/showLoader", false);
			},

			clearSearchFilters() {
				this.current_page = 1;
				this.searchCustomerName = "";
				this.selectCustomerCategory = [];
				this.selectCustomerTerritory = null;
				this.selectCustomerProvince = "";
				this.selectSalesRep = null;
				this.$store.dispatch("common/setPreviousView", {});
				this.getCustomerList();
			},

			goCustomerDetail(customer) {
				if (customer.customer_type === "PROSPECT") {
					this.$store.dispatch("customer/setProspectFlag", true);
				}
				if (
					customer.customer_type === "CUSTOMER" ||
					customer.customer_type === "CUSTOMER_LOCATION"
				) {
					this.$store.dispatch("customer/setProspectFlag", false);
				}
				this.$store.dispatch(
					"customer/setCustomerId",
					customer.erp_customer_id
				);
				this.$router.push({ name: "CompanyDetail" });
				// }
			},

			async openProspectModal() {
				this.newProspect = JSON.parse(JSON.stringify(prospectObj));
				this.newProspect.assign_to_sales_rep = this.user.user_id;
				this.showCreateProspectModal = true;
			},

			async searchProspectName() {
				this.searchingForDuplicateCompanies = true;
				await django_session
					.get("erp/customer/list/", {
						params: {
							duplicate_search_yn: true,
							keyword: this.newProspect.customer_name,
						},
					})
					.then((response) => {
						this.duplicateProspectList = response.data.results;
						this.duplicateProspectListLength = response.data.count;
						this.searchingForDuplicateCompanies = false;
					});
			},

			async validateProspect() {
				this.$refs.__validateProspectForm.validate().then((e) => {
					console.log("Validate Prospect Form", e);
					this.prospectFormIsValid = e;
					if (this.prospectFormIsValid) {
						// this.saveProspect()
						console.log("VALID");
						this.saveProspect();
					}
				});
			},

			async saveProspect() {
				this.prospectIsSaving = true;
				this.prospectSaveComplete = false;
				await django_session
					.post("erp/customer/create/", {
						customer_name: this.newProspect.customer_name,
						address_1: this.newProspect.address_1,
						address_2: this.newProspect.address_2,
						city: this.newProspect.city,
						province: this.newProspect.province,
						postal_code: this.newProspect.postal_code,
						company_phone: this.newProspect.company_phone,
						website: this.newProspect.website,
						territory: this.newProspect.territory,
						new_prospect_has_contact: this.newProspectHasContact,
						first_name: this.newProspect.first_name,
						last_name: this.newProspect.last_name,
						email: this.newProspect.email,
						phone: this.newProspect.phone,
						position: this.newProspect.position,
						notes: this.newProspect.notes,
						assign_to_sales_rep: this.newProspect.assign_to_sales_rep,
						notify_sales_rep_yn: this.notifySalesRep,
					})
					.then((response) => {
						this.prospectIsSaving = false;
						if (this.newProspect.notify_sales_rep_yn) {
							this.prospectAlertMessage =
								"New prospect created. Selected sales rep notified.";
						}
						if (!this.newProspect.notify_sales_rep_yn) {
							this.prospectAlertMessage = "New prospect created.";
						}
						this.prospectAlertVariant = "success";
						this.showProspectAlert = true;
						this.closeProspect();
					})
					.catch((error) => {
						console.log(error.response.data);
						this.prospectIsSaving = false;
						this.prospectAlertMessage =
							"There was a problem saving the new record.";
						this.prospectAlertVariant = "warning";
						this.showProspectAlert = true;
					});
				this.prospectSaveComplete = true;
			},

			closeProspect() {
				this.showCreateProspectModal = false;
				this.newProspect = JSON.parse(JSON.stringify(prospectObj));
				this.prospectAlertMessage = "";
				this.prospectAlertVariant = "";
				this.showProspectAlert = false;
				this.duplicateProspectList = [];
				this.duplicateProspectListLength = 0;
				this.getCustomerList();
			},
		},

		beforeRouteLeave(to, from, next) {
			let payload = {
				current_page: this.current_page,
				searchCustomerName: this.searchCustomerName,
				selectCustomerCategory: this.selectCustomerCategory,
				selectCustomerTerritory: this.selectCustomerTerritory,
				selectCustomerProvince: this.selectCustomerProvince,
				selectSalesRep: this.selectSalesRep,
				from_route: this.$route.path,
			};
			console.log("Leaving Route", payload);
			this.$store.dispatch("common/setPreviousView", payload);
			next();
		},
	};
</script>

<style lang="scss" scoped>
	.customer-name {
		font-weight: 700;
	}

	.narrow-column {
		width: 10px !important;
	}

	span {
		display: block;
	}
</style>
