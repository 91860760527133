export const contactObj = {
  contact_id: null,
  email: "",
  erp_customer_id: null,
  first_name: "",
  last_name: "",
  notes: "",
  phone: "",
  position: "",
  address_1: "",
  address_2: "",
  city: "",
  province: "",
  postal_code: "",
  // sage_customer_id: null,
  // sage_customer_location_id: null,
  customer_name: "",
  updated_by: null,
  updated_by_name: "",
  updated_at: "",
  created_by: null,
  created_by_name: "",
  created_at: "",
  is_active_yn: true,
}

export const prospectObj = {
  customer_name: "",
  address_1: "",
  address_2: "",
  city: "",
  province: "",
  postal_code: "",
  company_phone: "",
  website: "",
  territory: null,
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  position: "",
  notes: "",
  // call_date: null,
  // call_time: null,
  // category: null,
  // category_name: "",
  territory: null,
  assign_to_sales_rep: null,
  notify_sales_rep_yn: false,
}
