<template>
  <div v-if="!showLoader" class="">
    <m-page-title title="Sales Calls" />
    <b-alert
      v-model="showSalesCallAlert"
      :variant="salesCallAlertVariant"
      class="w-100"
      dismissible
      >{{ salesCallAlertMessage }}</b-alert
    >
    <b-row class="d-flex my-3">
      <b-colxx md="12" sm="12">
        <b-card>
          <b-row>
            <b-colxx md="9" sm="9"
              ><b-form
                id="customer-search-main"
                @submit.prevent="getSalesCallList"
              >
                <b-form-group class="mb-0">
                  <b-input-group class="">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-search"></i
                      ></span>
                    </div>
                    <b-form-input
                      v-model.trim="searchKeyword"
                      placeholder="Search by person, company, city name."
                      type="text"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-colxx>
            <!-- <div v-if="permissions.includes('CanCreateSalesCall')"> -->
            <b-colxx
              v-if="permissions.includes('CanCreateSalesCall')"
              md="3"
              sm="3"
            >
              <b-button
                v-if="!showSalesCallCard"
                class="bg-purple text-white float-right"
                @click="createNewSalesCall()"
              >
                <i class="fas fa-circle-plus text-white mr-1" />
                New</b-button
              >
            </b-colxx>
            <!-- </div> -->
          </b-row>
          <div class="row mt-3 align-items-center">
            <b-colxx md="2" sm="12">
              <b-form-group
                id="search-from-fieldset"
                label="From"
                label-for="search-from-date"
                class="field-label-form-group"
              >
                <b-form-input
                  id="search-from-date"
                  v-model="searchFromDate"
                  type="date"
                />
              </b-form-group>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="search-to-fieldset"
                label="To"
                label-for="search-to-date"
                class="field-label-form-group"
              >
                <b-form-input
                  id="search-to-date"
                  v-model="searchToDate"
                  type="date"
                />
              </b-form-group>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="salesrep-type-fieldset"
                label="Sales Rep"
                label-for="salesrep-select"
                class="field-label-form-group"
              >
                <b-form-select
                  id="salesrep-select"
                  v-model="selectSalesRep"
                  :options="salesRepList"
                  value-field="user_id"
                  text-field="full_name"
                ></b-form-select>
              </b-form-group>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="territory-type-fieldset"
                label="Territory"
                label-for="territory-select"
                class="field-label-form-group"
              >
                <b-form-select
                  id="territory-select"
                  v-model="selectCustomerTerritory"
                  :options="territoryList"
                  value-field="territory_id"
                  text-field="territory_name"
                ></b-form-select>
              </b-form-group>
            </b-colxx>
            <b-colxx md="3" sm="12">
              <b-button variant="success" @click="getSalesCallList()"
                >Search</b-button
              >
              <b-button variant="info" @click="clearSearchFilters()"
                >Clear</b-button
              >
            </b-colxx>
          </div>
        </b-card>
      </b-colxx>
    </b-row>

    <div id="sales-call-modal-div" v-if="showSalesCallCard" class="card p-4">
      <validation-observer ref="__validateSalesCallForm" v-slot="observer">
        <b-row>
          <b-colxx md="12" sm="12">
            <b-row class="my-1">
              <b-alert
                v-model="showSalesCallAlert"
                :variant="salesCallAlertVariant"
                class="w-100"
                dismissible
                >{{ salesCallAlertMessage }}</b-alert
              >
            </b-row>
          </b-colxx>
        </b-row>
        <sales-call
          ref="__salesCallDetail"
          :item="editSalesCall"
          :user-name="user.full_name"
          :user-id="user.user_id"
          :is-edit-sales-call="editSalesCallYN"
          @update-call-data="updateSalesCall($event)"
          @toggle-sales-call-footer="toggleSalesCallFooter($event)"
        />
        <div class="flex-row d-flex my-4 justify-content-end">
          <b-button
            v-if="
              !saveSalesCallComplete && permissions.includes('CanEditSalesCall')
            "
            class="mr-2"
            variant="info"
            :disabled="salesCallIsSaving || !observer.valid"
            @click="save()"
          >
            {{ salesCallSaveButtonLabel }}
          </b-button>
          <b-button
            variant="warning"
            :disabled="salesCallIsSaving"
            @click="closeSalesCall()"
            >Cancel</b-button
          >
        </div>
      </validation-observer>
    </div>
    <div class="my-4">
      <b-table
        id="sales-call-table"
        ref="__salesCallTable"
        head-variant="dark"
        :items="salesCallList"
        :fields="sales_call_list_fields"
        :current-page="current_page"
        :per-page="0"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width: field.key === 'notes' ? '300px' : '',
            }"
          />
        </template>
        <template #cell(actions)="row">
          <div v-if="permissions.includes('CanViewSalesCall')">
            <i
              class="fas fa-eye text-default icon-pointer d-inline"
              @click="goSalesCallDetail(row.item, row.index)"
            />
          </div>
          <i
            v-if="row.item.is_prospect_yn"
            class="fas fa-star text-purple ml-1 d-inline"
          />
        </template>
        <template #cell(call_date)="row">
          {{ new Date(row.item.call_date).toLocaleDateString() }}
          {{
            new Date(row.item.call_date).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: "true",
            })
          }}
        </template>
      </b-table>
      <b-pagination
        v-model="current_page"
        :total-rows="total_rows"
        :per-page="per_page"
        :number-of-pages="pageTotal"
        size="sm"
        class="my-0"
        aria-controls="sales-call-table"
        @input="getSalesCallList(current_page)"
      ></b-pagination>
    </div>

    <!--  -->
    <!-- Create Sales Call Modal -->
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";

import SalesCall from "@/views/customer/components/SalesCall.vue";

import {
  dateTimeToLocal,
  dateTimeNoOffsetNoSeconds,
  newLocalTodayDateObject,
} from "@/functions/time";
import { salesCallObj } from "@/constants/objects/salescall";
import { updateWithResponseData } from "@/functions/common";

import { saveSalesCall } from "@/functions/salescall";

export default {
  name: "SalesCallHome",
  components: {
    SalesCall,
  },

  data() {
    return {
      newLocalTodayDateObject,
      salesCallList: [],
      territoryList: [],
      salesRepList: [],
      searchKeyword: "",
      selectSalesRep: null,
      selectCustomerTerritory: null,
      searchFromDate: null,
      searchToDate: null,

      total_rows: null,
      prev_page_link: "",
      next_page_link: "",
      current_page: 1,
      per_page: 10,

      sales_call_list_fields: [
        {
          label: "",
          key: "actions",
        },
        {
          label: "Company",
          key: "customer_name",
        },
        {
          label: "Contact",
          key: "contact_name",
        },
        {
          label: "Notes",
          key: "notes",
          thStyle: {
            width: "700px",
          },
        },
        {
          label: "Category",
          key: "category_name",
        },
        {
          label: "Created By",
          key: "created_by_name",
        },
        {
          label: "Call Date",
          key: "call_date",
        },
      ],

      salesCallObj,

      editSalesCall: {},
      editSalesCallIndex: null,
      editSalesCallYN: null,
      salesCallReadOnlyYN: null,

      showSalesCallCardFooter: true,

      showSalesCallCard: false,
      showSalesCallAlert: false,
      salesCallAlertMessage: "",
      salesCallAlertVariant: "",

      salesCallIsSaving: false,
      saveSalesCallComplete: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "roles", "permissions"]),
    ...mapGetters("common", ["showLoader"]),

    pageTotal() {
      var page_total = 1;
      page_total = Math.ceil(this.total_rows / this.per_page);
      return page_total;
    },

    salesCallModalTitle() {
      if (this.editSalesCallYN === true) {
        return "Edit Sales Call Record";
      } else {
        return "Create New Sales Call";
      }
    },

    salesCallSaveButtonLabel() {
      if (this.salesCallIsSaving === true) {
        return "Saving...";
      } else {
        return "Save";
      }
    },

    disableSaveButton() {
      if (this.$refs.__validateSalesCallForm.validate()) {
        return true;
      }
      return false;
    },
  },

  created() {},

  async mounted() {
    this.$store.dispatch("common/showLoader", true);
    document.title = "Sales Calls";

    await django_session
      .get("erp/customer/territory/list/", {
        params: {
          page_size: 0,
        },
      })
      .then((response) => {
        this.territoryList = response.data;
      });
    await django_session
      .get("erp/user/list-sales-rep/", {
        params: {
          page_size: 0,
        },
      })
      .then((response) => {
        this.salesRepList = response.data.results;
      });

    django_session("erp/customer/sales-call/list/").then((response) => {
      this.salesCallList = response.data.results;
      this.total_rows = response.data.count;
      this.prev_page_link = response.data.links.previous;
      this.next_page_link = response.data.links.next;
      this.current_page = response.data.page;
      this.$store.dispatch("common/showLoader", false);
    });
  },

  methods: {
    async getSalesCallList() {
      this.$store.dispatch("common/showLoader", true);
      await django_session("erp/customer/sales-call/list/", {
        params: {
          page: this.current_page,
          search_keyword: this.searchKeyword,
          search_from_date: this.searchFromDate,
          search_to_date: this.searchToDate,
          search_sales_rep: this.selectSalesRep,
          search_territory: this.selectCustomerTerritory,
        },
      })
        .then((response) => {
          this.salesCallList = response.data.results;
          this.total_rows = response.data.count;
          this.prev_page_link = response.data.links.previous;
          this.next_page_link = response.data.links.next;
          this.current_page = response.data.page;
          this.$store.dispatch("common/showLoader", false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          if (
            error.response.status === 404 &&
            error.response.data.detail == "Invalid page."
          ) {
            console.log("Rerun search");
            this.current_page = 1;
            this.getSalesCallList();
            this.$store.dispatch("common/showLoader", false);
          }
          this.$store.dispatch("common/showLoader", false);
        });
    },

    clearSearchFilters() {
      this.searchKeyword = "";
      this.searchFromDate = null;
      this.searchToDate = null;
      this.selectSalesRep = null;
      this.selectCustomerTerritory = null;
      this.current_page = 1;
      this.getSalesCallList();
    },

    goSalesCallDetail(item, index) {
      this.editSalesCallYN = true;
      this.salesCallReadOnlyYN = true;
      this.editSalesCall = JSON.parse(JSON.stringify(item));

      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var testingOne = new Date(
        new Date(this.editSalesCall.call_date) - tzoffset
      )
        .toISOString()
        .replace(/:[^:]*$/, "");

      this.editSalesCall.call_date = testingOne;

      let follow_up_object = {
        activity_follow_up_yn: false,
        activity_reminder_date: null,
        activity_task_subject: "",
        activity_assign_to: this.user.user_id,
        activity_follow_up_note: "",
        activity_create_outlook_task_yn: false,
        outlook_to_do_list: this.user.default_to_do_list,
      };
      Object.assign(this.editSalesCall, follow_up_object);

      this.editSalesCallIndex = index;
      this.showSalesCallCard = true;
    },

    toggleSalesCallFooter(value) {
      console.log(value);
      this.showSalesCallCardFooter = value;
    },

    createNewSalesCall() {
      this.editSalesCallYN = false;
      this.salesCallReadOnlyYN = false;
      this.editSalesCall = JSON.parse(JSON.stringify(salesCallObj));

      this.editSalesCall.call_date = newLocalTodayDateObject();

      this.editSalesCall.user_name = this.user.full_name;
      this.editSalesCall.user = this.user.user_id;
      this.editSalesCall.activity_assign_to = this.user.user_id;
      if (this.user.default_to_do_list !== "") {
        this.editSalesCall.outlook_to_do_list = this.user.default_to_do_list;
      }
      this.editSalesCallIndex = null;
      this.showSalesCallCard = true;
    },

    updateSalesCall(event) {
      this.editSalesCall = event;
    },

    async save() {
      this.salesCallIsSaving = true;
      let saveSalesCallRecord = await saveSalesCall(
        this.editSalesCall,
        this.editSalesCallYN
      );
      console.log("Wait for Function", saveSalesCallRecord);
      if (saveSalesCallRecord.status === 200) {
        this.salesCallAlertMessage = "Sales call information updated.";
        this.salesCallAlertVariant = "success";
        this.showSalesCallAlert = true;
        this.salesCallIsSaving = false;
        this.saveSalesCallComplete = true;

        if (this.editSalesCallYN) {
          let updatedData = updateWithResponseData(
            this.editSalesCall,
            this.salesCallList,
            this.editSalesCallIndex,
            saveSalesCallRecord.data,
            false
          );
          console.log("Updated Data after save", updatedData);
          this.editSalesCall.modified_by_name =
            updatedData.updatedObject.modified_by_name;
          this.editSalesCall.modified_at =
            updatedData.updatedObject.modified_at;
        }
        if (!this.editSalesCallYN) {
          this.salesCallList.unshift(saveSalesCallRecord.data);
        }
      } else if (saveSalesCallRecord.status === 500) {
        this.salesCallAlertMessage =
          "Server Error. Please contact the developer.";
        this.salesCallAlertVariant = "danger";
        this.showSalesCallAlert = true;
        this.salesCallIsSaving = false;
        this.saveSalesCallComplete = true;
      }

      this.salesCallIsSaving = false;
      this.saveSalesCallComplete = true;
      this.closeSalesCall();
    },

    closeSalesCall() {
      this.showSalesCallCard = false;
      this.editSalesCallYN = false;
      this.saveSalesCallComplete = false;
    },
  },
};
</script>

<style scoped>
.table td th {
  white-space: normal !important;
}

.wide-column {
  width: 700px !important;
}
</style>
