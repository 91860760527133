<template>
  <div v-if="!showLoader">
    <div v-if="!openCompanySearch">
      <b-alert
        v-model="showContactAlert"
        :variant="contactAlertVariant"
        class="w-100 align-items-center"
        dismissible
      >
        <span class="flex-column flex-grow-1 text-white">
          {{ contactAlertMessage }}
        </span>
      </b-alert>
      <b-form-row class="align-items-center">
        <b-colxx
          md="4"
          sm="12">
          <m-text-input
            id="first-name"
            label="First Name"
            :rules="{ required: true, max: 50 }"
            :value="contactData.first_name"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="First Name"
            @update-value="
              (input) => {
                contactData.first_name = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="4"
          sm="12">
          <m-text-input
            id="last-name"
            label="Last Name"
            :rules="{ required: true, max: 75 }"
            :value="contactData.last_name"
            :alternative="false"
            :group="false"
            name="Last Name"
            :disabled="!canEditContact"
            @update-value="
              (input) => {
                contactData.last_name = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <b-form-group
            id="contact-active-fieldset"
            label="Active"
            label-for="contact-active-yn"
            class="field-label-form-group mt-2">
            <b-form-checkbox
              id="contact-active-yn"
              v-model="contactData.is_active_yn"
              :disabled="!canEditContact"
              @change="
                () => {
                  updateContactData()
                }
              "></b-form-checkbox>
          </b-form-group>
        </b-colxx>
        <b-colxx md="2" sm="12">
          <b-button variant="danger" @click="confirmDeleteContact()">Delete</b-button>
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="email"
            label="Email"
            :rules="{ email: true }"
            :value="contactData.email"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="Email"
            @update-value="
              (input) => {
                contactData.email = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <div v-if="canEditContact">
            <m-text-input
              id="company-name"
              label="Company"
              :value="contactData.customer_name"
              :alternative="false"
              :group="false"
              :readonly="companyIsReadOnly"
              append-icon="pencil"
              @append-icon-action="changeCompany()"
              :show-append-action="showEditCompany"
              name="Company"
              @update-value="
                (input) => {
                  contactData.customer_name = input
                  updateContactData()
                }
              " />
          </div>
          <div v-if="!canEditContact">
            <m-text-input
              id="company-name"
              label="Company"
              :value="contactData.customer_name"
              :alternative="false"
              :group="false"
              :readonly="companyIsReadOnly"
              name="Company"
              @update-value="
                (input) => {
                  contactData.customer_name = input
                  updateContactData()
                }
              " />
          </div>
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="address-1"
            label="Address 1"
            :rules="{ required: true }"
            :value="contactData.address_1"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="Address 1"
            @update-value="
              (input) => {
                contactData.address_1 = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="address-2"
            label="Address 2"
            :rules="{ required: false }"
            :value="contactData.address_2"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="Address 2"
            @update-value="
              (input) => {
                contactData.address_2 = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="city"
            label="City"
            :rules="{ required: true }"
            :value="contactData.city"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="City"
            @update-value="
              (input) => {
                contactData.city = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <b-form-group
            id="prov-type-fieldset"
            label="Province *"
            label-for="prov-select"
            class="field-label-form-group mt-2">
            <b-form-select
              id="prov-select"
              v-model="contactData.province"
              :options="province"
              :disabled="!canEditContact"
              @update-value="
                (input) => {
                  contactData.province = input
                  updateContactData()
                }
              "></b-form-select>
          </b-form-group>
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="postal-code"
            label="Postal Code"
            :rules="{ required: true, max: 7 }"
            :value="contactData.postal_code"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="Potal Code"
            @update-value="
              (input) => {
                contactData.postal_code = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="phone"
            label="Phone"
            type="phone"
            :rules="{ required: false, max: 50 }"
            :value="contactData.phone"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="Phone"
            @update-value="
              (input) => {
                contactData.phone = input
                updateContactData()
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="position"
            label="Position"
            :rules="{ max: 50 }"
            :value="contactData.position"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            name="Position"
            @update-value="
              (input) => {
                contactData.position = input
                updateContactData()
              }
            " />
        </b-colxx>
      </b-form-row>
      <b-form-row>
        <b-colxx
          cols="12"
          class="p-0">
          <m-text-area
            id="contact-note"
            :value="contactData.notes"
            name="Notes"
            :rows="7"
            :rules="{ max: 4000 }"
            label="Notes"
            :alternative="false"
            :group="false"
            :disabled="!canEditContact"
            @update-value="(input) => (contactData.notes = input)" />
        </b-colxx>
      </b-form-row>
      <div v-if="showUpdatedFields">
        <m-tracking-info
          :created-by-name="contactData.created_by_name"
          :created-at="contactData.created_at"
          :modified-by-name="contactData.updated_by_name"
          :modified-at="contactData.updated_at" />
      </div>
    </div>
    <div v-if="openCompanySearch">
      <m-spinny-message
        v-if="isSearchingForCompany"
        message="Searching company database, please wait..." />
      <div v-if="!isSearchingForCompany">
        <b-form
          id="customer-search-main"
          @submit.prevent="searchForCompany">
          <b-row class="my-4">
            <b-colxx
              md="9"
              sm="12">
              <b-form-group class="mb-0">
                <b-input-group class="">
                  <b-form-input
                    v-model="searchCompanyName"
                    placeholder="Search by customer name"
                    type="text">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-colxx>
            <b-colxx
              md="3"
              sm="12"
              ><b-button
                variant="success"
                class="ml-1"
                @click="searchForCompany()"
                >Search</b-button
              >
              <b-button
                variant="info"
                class="ml-1"
                @click="() => cancelSearchCompany()"
                >Cancel</b-button
              ></b-colxx
            >
          </b-row>
        </b-form>
        <b-table
          id="customer-table"
          ref="__customerTable"
          responsive
          head-variant="dark"
          :items="companySearchResults"
          :fields="customer_list_fields">
          <template #cell(select)="row">
            <i
              class="fas fa-circle-check text-default icon-pointer"
              v-b-popover.hover.top="'Select'"
              @click="selectNewCompany(row.item)" />
          </template>
          <template #cell(sname)="row">
            {{ row.item.customer_name }}
            <span
              v-if="row.item.lid === null"
              class="1 fas fa-star text-purple ml-1" />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
  import django_session from "@/api/django_session"
  import { province } from "@/constants/province"
  import { mapGetters } from "vuex"

  export default {
    name: "Contact",
    components: {},

    props: {
      item: {
        type: Object,
        required: true,
        default: () => {},
      },
      editContactYN: {
        type: Boolean,
        required: true,
        default: false,
      },
      customerLocations: {
        type: Array,
        required: false,
        default: () => [],
      },
      companyDetail: {
        type: Object,
        required: false,
        default: () => {},
      },
      showUpdatedFields: {
        type: Boolean,
        required: false,
        default: true,
      },
      showEditCompany: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    emits: ["update-contact-data", "hide-tabs-buttons", "hide-save-contact-button", "delete-contact"],

    data() {
      return {
        province: province,
        contactData: {},
        companyIsReadOnly: true,
        useCompanyMailingAddress: true,

        // getNewCompany: false,
        searchCompanyName: "",
        openCompanySearch: false,
        companySearchResults: [],
        isSearchingForCompany: false,
        customer_list_fields: [
          {
            label: "",
            key: "select",
          },
          {
            label: "Name",
            key: "customer_name",
          },
          {
            label: "City",
            key: "city",
          },
          {
            label: "Territory",
            key: "sales_territory",
          },
        ],

        customerLocationList: [],
        customer: {},
        showBlankAddressAlert: false,

        showContactAlert: false,
        contactAlertVariant: "",
        contactAlertMessage: "",
        // showContactAlertButton: true,
      }
    },

    computed: {
      ...mapGetters("auth", ["isAuthenticated", "user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      canEditContact() {
        if (this.permissions.includes("CanEditContact")) {
          return true
        } else {
          return false
        }
      },
    },

    created() {},

    mounted() {
      this.contactData = JSON.parse(JSON.stringify(this.item))
      this.customerLocationList = JSON.parse(JSON.stringify(this.customerLocations))
      if (this.companyDetail !== undefined) {
        if (this.companyDetail.busemailad === 1) {
          this.useCompanyMailingAddress = true
          this.contactData.address_1 = this.companyDetail.sstreet1
          this.contactData.address_2 = this.companyDetail.sstreet2
          this.contactData.city = this.companyDetail.scity
          this.contactData.province = this.companyDetail.sprovstate
          this.contactData.postal_code = this.companyDetail.spostalzip

        }
          this.$emit("update-contact-data", this.contactData)
      }
    },

    methods: {
      formatPhoneNumber() {
        var value = this.contactData.phone
        var formattedInput = value.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        this.contactData.phone = formattedInput
      },

      updateContactData() {
        this.$emit("update-contact-data", this.contactData)
      },

      confirmDeleteContact() {
        this.$bvModal.msgBoxConfirm("Are you sure you want to delete this contact?", {
          size: "sm",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
          .then((value) => {
            if (value) {
              this.deleteContact()
            }
          })
          .catch((err) => {
            // An error occurred
          })
      },

      deleteContact() {
        this.$emit("delete-contact", this.contactData)
      },

      changeCompany() {
        console.log("Change Company")
        // this.getNewCompany = true;
        this.searchCompanyName = ""
        this.openCompanySearch = true
        this.$emit("hide-save-contact-button", true)
        this.$emit("hide-tabs-buttons", true)
      },

      async searchForCompany() {
        this.isSearchingForCompany = true
        await django_session
          .get("erp/customer/search/", {
            params: {
              page_size: 0,
              keyword: this.searchCompanyName,
            },
          })
          .then((response) => {
            this.companySearchResults = response.data
          })
        this.isSearchingForCompany = false
      },

      cancelSearchCompany() {
        this.openCompanySearch = false
        this.companySearchResults = []
        this.searchCompanyName = ""
        this.$emit("hide-tabs-buttons", false)
        this.$emit("hide-save-contact-button", false)
      },

      selectNewCompany(item) {
        console.log(item)
        this.openCompanySearch = false
        this.companySearchResults = []
        this.searchCompanyName = ""
        // this.contactData.sage_customer_id = item.sage_customer_id
        this.contactData.customer_name = item.customer_name
        this.contactData.erp_customer_id = item.erp_customer_id
        this.contactData.erp_customer = item.erp_customer_id
        this.useCompanyMailingAddress = true
        this.contactData.address_1 = item.address_1
        this.contactData.address_2 = item.address_2
        this.contactData.city = item.city
        this.contactData.province = item.province
        this.contactData.postal_code = item.postal_code
        // this.contactData.sage_customer_location_id = null
        this.$emit("update-contact-data", this.contactData)
        this.$emit("hide-save-contact-button", false)
        this.$emit("hide-tabs-buttons", false)
      },
    },
  }
</script>
