<template>
  <div>
    <div id="sales-call">
      <b-row>
        <b-colxx md="12" sm="12"> </b-colxx>
      </b-row>
      <b-alert variant="info" v-model="showSalesCallInfoMessage" dismissible>{{
        salesCallInfoMessage
      }}</b-alert>
      <div v-if="showSalesCallDetail">
        <div v-if="showContactSearch && canEditSalesCall">
          <b-form-row
            ><b-colxx md="12" sm="12"
              ><b-form @submit.prevent="searchContacts">
                <m-text-input
                  id="contact-search"
                  label="Search for contact"
                  :value="searchForContactName"
                  :alternative="false"
                  :readonly="false"
                  :group="false"
                  name="Search for Contact"
                  append-icon="fas fa-search text-success icon-pointer"
                  @append-icon-action="
                    () => {
                      searchContacts();
                    }
                  "
                  @update-value="
                    (input) => {
                      searchForContactName = input;
                    }
                  "
                  @search-contacts="searchContacts()"
                /> </b-form></b-colxx
          ></b-form-row>
        </div>

        <b-form-row>
          <b-colxx md="6" sm="12">
            <m-text-input
              id="contact-name"
              label="Contact Name"
              :value="salesCallData.contact_name"
              v-if="!contactDropdownYn"
              :alternative="true"
              :readonly="true"
              :rules="{ required: true }"
              :group="false"
              name="Contact Name"
              @update-value="
                (input) => {
                  salesCallData.contact_name = input;
                  updateCallData();
                }
              "
            />
            <label class="field-label-form-group mt-2">Contact Name *</label>
            <b-form-select
              id="contact-select"
              v-if="contactDropdownYn"
              v-model="salesCallData.contact"
              :options="contactOptions"
              value-field="contact_id"
              text-field="full_name"
            />
          </b-colxx>
          <b-colxx md="6" sm="12">
            <m-text-input
              id="sales-rep-name"
              label="Sales Rep"
              :value="salesCallData.user_name"
              :alternative="true"
              :readonly="true"
              :rules="{ required: true }"
              :group="false"
              name="Sales Rep"
              @update-value="
                (input) => {
                  salesCallData.user_name = input;
                  updateCallData();
                }
              "
            />
          </b-colxx>
          <b-colxx md="6" sm="12">
            <m-text-input
              id="call-date"
              label="Call Date"
              :value="salesCallData.call_date"
              :alternative="false"
              :rules="{ required: true }"
              type="datetime-local"
              step="900"
              :group="false"
              :disabled="!canEditSalesCall"
              name="Call Date"
              @update-value="
                (input) => {
                  salesCallData.call_date = input;
                  updateCallData();
                }
              "
            />
          </b-colxx>
          <b-colxx md="6" sm="12">
            <m-text-input
              id="customer-name"
              label="Customer"
              :value="salesCallData.customer_name"
              :alternative="true"
              :readonly="true"
              :group="false"
              name="Customer"
              @update-value="
                (input) => {
                  salesCallData.customer_name = input;
                  updateCallData();
                }
              "
            />
          </b-colxx>
          <b-colxx md="6" sm="12">
            <validation-provider
              rules="required"
              name="Category"
              v-bind="$attrs"
              v-slot="{ errors }"
            >
              <label class="field-label-form-group mt-2">Category *</label>
              <b-form-select
                v-model="salesCallData.category"
                :options="salesCallCategories"
                label-field="'Category'"
                :required="true"
                :disabled="!canEditSalesCall"
                value-field="sales_call_category_id"
                text-field="sales_call_category"
                class="custom-input-select form-control"
                @change="updateCallData()"
              />
              <div
                v-if="errors[0]"
                class="invalid-feedback text-xs"
                style="display: inline-block"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-colxx>
          <b-colxx md="12" sm="12">
            <m-text-area
              id="call-data-note"
              :value="salesCallData.notes"
              name="Notes"
              :rows="7"
              :rules="{ required: false, max: 4000 }"
              label="Notes"
              :alternative="false"
              :group="false"
              :disabled="!canEditSalesCall"
              @update-value="
                (input) => {
                  salesCallData.notes = input;
                  // updateCallData();
                }
              "
              @blur="updateCallData()"
            />
          </b-colxx>
        </b-form-row>
        <b-form-row class="my-2"
          ><b-colxx md="12" sm="12">
            <b-table
              id="activity-table"
              ref="__activityTable"
              head-variant="dark"
              responsive
              :items="salesCallData.activities"
              :fields="activity_list_fields"
              :per-page="0"
            >
              <template #cell(due_date)="row">
                {{ new Date(row.item.due_date).toLocaleDateString() }}
              </template>
              <template #cell(related_outlook_task)="row">
                <div v-if="row.item.related_outlook_task_id !== ''">
                  <i class="fas fa-circle-check text-success" />
                </div>
              </template>
            </b-table> </b-colxx
        ></b-form-row>
        <b-form-row>
          <b-colxx md="12" sm="12"
            ><b-alert v-model="noDefaultUserToDoList" variant="warning"
              >No default to do list selected. Go to your user profile and
              select a default list.</b-alert
            ></b-colxx
          >
        </b-form-row>
        <b-form-row>
          <b-colxx md="3" sm="12">
            <b-form-group
              id="followup-activity-fieldset"
              label="Create Follow Up Activity?"
              label-for="create-activity-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="create-activity-yn"
                :disabled="!canEditSalesCall"
                v-model="salesCallData.activity_follow_up_yn"
                @update="updateCallData()"
                @change="setTaskFields()"
              ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
          <b-colxx v-if="salesCallData.activity_follow_up_yn" md="6" sm="12">
            <validation-provider
              rules="required"
              name="Assign To"
              v-bind="$attrs"
              v-slot="{ errors }"
            >
              <label class="field-label-form-group mt-2"
                >Follow Up Assigned To *</label
              >
              <b-form-select
                v-model="salesCallData.activity_assign_to"
                :options="userList"
                label-field="'Assign Activity To'"
                :required="true"
                :disabled="!canEditSalesCall"
                value-field="user_id"
                text-field="full_name"
                class="custom-input-select form-control"
                @change="updateCallData()"
              />
              <div
                v-if="errors[0]"
                class="invalid-feedback text-xs"
                style="display: inline-block"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-colxx>
          <b-colxx v-if="showOutlookTaskCheckbox" md="3" sm="12">
            <b-form-group
              id="create-outlook-task-fieldset"
              label="Create Outlook Task Reminder?"
              label-for="create--outlook-task-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="create-outlook-task-yn"
                :disabled="!canEditSalesCall"
                v-model="salesCallData.activity_create_outlook_task_yn"
                @update="updateCallData()"
              ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
        </b-form-row>

        <b-form-row v-if="salesCallData.activity_follow_up_yn">
          <b-colxx md="3" sm="12">
            <m-text-input
              id="reminder-date"
              label="Reminder Date"
              :value="salesCallData.activity_reminder_date"
              :alternative="false"
              :rules="{ required: salesCallData.activity_follow_up_yn }"
              type="datetime-local"
              step="900"
              :group="false"
              :disabled="!canEditSalesCall"
              name="Reminder Date"
              @update-value="
                (input) => {
                  salesCallData.activity_reminder_date = input;
                  updateCallData();
                }
              "
            />
          </b-colxx>

          <b-colxx md="3" sm="12">
            <m-text-input
              id="due-date"
              label="Due Date"
              :value="salesCallData.activity_due_date"
              :alternative="false"
              :rules="{ required: salesCallData.activity_follow_up_yn }"
              type="datetime-local"
              step="900"
              :group="false"
              :disabled="!canEditSalesCall"
              name="Due Date"
              @update-value="
                (input) => {
                  salesCallData.activity_due_date = input;
                  updateCallData();
                }
              "
            />
          </b-colxx>

          <b-colxx md="6" sm="12">
            <m-text-input
              id="task-subject"
              label="Subject"
              :value="salesCallData.activity_task_subject"
              :rules="{ required: salesCallData.follow_up_yn }"
              :alternative="false"
              :group="false"
              name="Subject"
              :disabled="!canEditSalesCall"
              @update-value="
                (input) => {
                  salesCallData.activity_task_subject = input;
                  updateCallData();
                }
              "
            />
          </b-colxx>

          <b-colxx
            v-if="salesCallData.activity_create_outlook_task_yn"
            md="12"
            sm="12"
          >
            <validation-provider
              rules="required"
              name="Choose a task list"
              v-bind="$attrs"
              v-slot="{ errors }"
            >
              <label class="field-label-form-group mt-2"
                >Outlook To Do List *</label
              >
              <b-form-select
                v-model="salesCallData.outlook_to_do_list"
                :options="outlookToDoListOptions"
                label-field="'Outlook To Do List'"
                :required="true"
                value-field="id"
                text-field="displayName"
                :disabled="!canEditSalesCall"
                class="custom-input-select form-control"
                @change="updateCallData()"
              />
              <div
                v-if="errors[0]"
                class="invalid-feedback text-xs"
                style="display: inline-block"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-colxx>

          <b-colxx md="12" sm="12">
            <m-text-area
              id="follow-up-note"
              :value="salesCallData.activity_follow_up_note"
              name="Task Notes"
              :rows="7"
              :rules="{ required: false, max: 4000 }"
              label="Task Notes"
              :alternative="false"
              :group="false"
              :disabled="!canEditSalesCall"
              @update-value="
                (input) => {
                  salesCallData.activity_follow_up_note = input;
                  //updateCallData();
                }
              "
              @blur="updateCallData()"
            />
          </b-colxx>
        </b-form-row>
        <!-- </validation-observer> -->

        <m-tracking-info
          :created-by-name="salesCallData.created_by_name"
          :created-at="salesCallData.created_at"
          :modified-by-name="salesCallData.modified_by_name"
          :modified-at="salesCallData.modified_at"
        />
      </div>

      <div v-if="showSearchSideBar">
        <div v-if="!createContact">
          <b-table
            id="search-contact-table"
            ref="__searchContactTable"
            responsive
            head-variant="dark"
            :items="contactSearchResults"
            :fields="contact_search_fields"
          >
            <template #cell(select)="row">
              <i
                class="fas fa-circle-check text-primary icon-pointer"
                @click="selectContact(row.item)"
              />
            </template>
            <template #custom-foot>
              <td colspan="3">
                <b-button variant="info" @click="closeContactSearch()"
                  >Close</b-button
                >
                <b-button variant="primary" @click="createNewContact()"
                  >Create New Contact</b-button
                >
              </td>
            </template>
          </b-table>
        </div>
      </div>
      <div v-if="createContact">
        <validation-observer ref="__validateContactForm" v-slot="observer">
          <contact
            :item="newContactData"
            :edit-contact-y-n="false"
            :show-updated-fields="false"
            @hide-save-contact-button="toggleSaveButtons($event)"
            @update-contact-data="updateContctData($event)"
          />
          <b-row v-if="!hideButtons" class="my-1 mx-auto">
            <b-button
              variant="success"
              :disabled="!observer.valid"
              @click="saveNewContact()"
              >Save</b-button
            >
            <b-button variant="warning" @click="cancelNewContact()"
              >Cancel</b-button
            >
          </b-row>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import django_session from "@/api/django_session";
import { mapGetters } from "vuex";

import Contact from "@/views/customer/components/Contact.vue";
import { contactObj } from "@/constants/objects";

export default {
  name: "SalesCall",
  components: {
    Contact,
  },

  props: {
    userName: {
      type: String,
      required: true,
      default: "",
    },
    userId: {
      type: Number,
      required: true,
      default: null,
    },
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
    isEditSalesCall: {
      type: Boolean,
      required: true,
    },
    showContactSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    contactDropdownYn: {
      type: Boolean,
      required: false,
      default: false,
    },
    contactOptions: {
      type: Array,
      required: false,
    },
  },

  emits: ["update-call-data", "toggle-sales-call-footer"],

  data() {
    return {
      salesCallCategories: [],
      salesCallAlertMessage: "",
      salesCallAlertVariant: "",
      showSalesCallAlert: false,

      salesCallInfoMessage: "",
      showSalesCallInfoMessage: false,

      salesCallData: {},
      searchForContactName: "",
      searchContactResults: [],
      showSearchSideBar: false,
      contactSearchResults: [],
      contact_search_fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "full_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "customer_name",
          label: "Company",
        },
      ],

      createContact: false,
      contactObj,
      hideButtons: false,
      newContactData: {},

      activity_list_fields: [
        {
          key: "assigned_to_name",
          label: "Assigned To",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "due_date",
          label: "Due Date",
        },
        {
          key: "subject",
          label: "Subject",
        },
        {
          key: "related_outlook_task",
          label: "In Outlook?",
        },
      ],

      userList: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "outlookToDoListOptions", "permissions"]),

    disableSaveContactButton() {
      if (this.$refs.__validateContactForm.validate()) {
        return true;
      }
      return false;
    },

    canEditSalesCall() {
      if (this.permissions.includes("CanEditSalesCall")) {
        return true;
      } else {
        return false;
      }
    },

    showSalesCallDetail() {
      if (this.showSearchSideBar) {
        return false;
      }
      return true;
    },

    showSalesCallFollowUpFields() {
      if (this.salesCallData.activity_follow_up_yn === true) {
        return true;
      }
      return false;
    },

    showOutlookTaskCheckbox() {
      if (
        this.salesCallData.activity_follow_up_yn &&
        this.salesCallData.activity_assign_to === this.user.user_id
      ) {
        return true;
      }
      return false;
    },

    noDefaultUserToDoList() {
      if (
        (this.salesCallData.activity_create_outlook_task_yn &&
          this.user.default_to_do_list === "") ||
        this.user.default_to_do_list === null ||
        this.user.default_to_do_list === undefined
      ) {
        return true;
      }
      return false;
    },
  },

  created() {},

  async mounted() {
    await django_session
      .get("erp/customer/sales-call-category/list/", {
        params: {
          page_size: 0,
        },
      })
      .then((response) => {
        let list = response.data;
        this.salesCallCategories = list.filter((x) => x.active_yn === true);
      });

    this.salesCallData = JSON.parse(JSON.stringify(this.item));

    await django_session
      .get("erp/user/list/", {
        // params: {
        //   page: 0,
        // },
      })
      .then((response) => {
        this.userList = response.data.results;
        this.salesCallData.assign_task_to = this.userId;
      });
  },

  methods: {
    updateCallData() {
      this.$emit("update-call-data", this.salesCallData);
    },

    async searchContacts() {
      this.$emit("toggle-sales-call-footer", false);
      await django_session
        .get("erp/customer/contact/search", {
          params: {
            keyword: this.searchForContactName,
            page_size: 0,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.contactSearchResults = response.data;
          this.showSearchSideBar = true;
          this.salesCallInfoMessage =
            "Select contact to assign to the sales call.";
          this.showSalesCallInfoMessage = true;
        });
    },

    setTaskFields() {
      if (this.salesCallData.activity_follow_up_yn === true) {
        this.salesCallData.activity_task_subject =
          "Follow up with " + this.salesCallData.contact_name;
        this.salesCallData.activity_follow_up_note =
          "Original Call: " +
          new Date(this.salesCallData.call_date).toLocaleDateString();
      }
      if (this.salesCallData.activity_follow_up_yn === false) {
        this.salesCallData.activity_task_subject = "";
        this.salesCallData.activity_follow_up_note = "";
      }
    },

    closeContactSearch() {
      this.$emit("toggle-sales-call-footer", true);
      this.showSearchSideBar = false;
      this.searchForContactName = "";
      this.contactSearchResults = [];
      this.showSalesCallInfoMessage = false;
      this.salesCallInfoMessage = "";
    },

    selectContact(item) {
      // console.log(item);
      this.salesCallData.contact_name = item.full_name;
      this.salesCallData.contact = item.contact_id;
      this.salesCallData.customer = item.erp_customer_id;
      this.salesCallData.customer_name = item.customer_name;
      this.salesCallData.contact_email = item.email;
      this.salesCallData.contact_phone = item.phone;
      this.salesCallData.is_new_contact_yn = false;
      this.showSearchSideBar = false;
      this.showSalesCallInfoMessage = false;
      this.salesCallInfoMessage = "";
      this.$emit("toggle-sales-call-footer", true);
      this.updateCallData();
    },

    createNewContact() {
      this.showSalesCallInfoMessage = true;
      this.salesCallInfoMessage = "Create new contact";
      this.newContactData = JSON.parse(JSON.stringify(contactObj));
      this.createContact = true;
    },

    updateContctData(event) {
      this.newContactData = event;
    },

    cancelNewContact() {
      this.createContact = false;
      this.showSearchSideBar = false;
      this.showSalesCallInfoMessage = false;
      this.$emit("toggle-sales-call-footer", true);
      // this.toggleSaveButtons(false);
    },

    toggleSaveButtons(value) {
      this.hideButtons = value;
    },

    saveNewContact() {
      django_session
        .post("erp/customer/contact/create/", {
          first_name: this.newContactData.first_name,
          last_name: this.newContactData.last_name,
          email: this.newContactData.email,
          phone: this.newContactData.phone,
          position: this.newContactData.position,
          notes: this.newContactData.notes,
          // sage_customer_id: this.newContactData.sage_customer_id,
          // sage_customer_location_id: this.newContactData.sage_customer_location_id,
          erp_customer_id: this.newContactData.erp_customer_id,
          is_active_yn: this.newContactData.is_active_yn,
          address_1: this.newContactData.address_1,
          address_2: this.newContactData.address_2,
          city: this.newContactData.city,
          province: this.newContactData.province,
          postal_code: this.newContactData.postal_code,
          created_by: this.user.user_id,
          updated_by: this.user.user_id,
        })
        .then((response) => {
          console.log(response.data);
          this.salesCallData.contact_name = response.data.full_name;
          this.salesCallData.contact = response.data.contact_id;
          this.salesCallData.customer = response.data.erp_customer_id;
          this.salesCallData.customer_name = response.data.customer_name;
          this.createContact = false;
          this.showSearchSideBar = false;
          this.$emit("toggle-sales-call-footer", true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped></style>
