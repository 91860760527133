var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"sales-call"}},[_c('b-row',[_c('b-colxx',{attrs:{"md":"12","sm":"12"}})],1),_c('b-alert',{attrs:{"variant":"info","dismissible":""},model:{value:(_vm.showSalesCallInfoMessage),callback:function ($$v) {_vm.showSalesCallInfoMessage=$$v},expression:"showSalesCallInfoMessage"}},[_vm._v(_vm._s(_vm.salesCallInfoMessage))]),(_vm.showSalesCallDetail)?_c('div',[(_vm.showContactSearch && _vm.canEditSalesCall)?_c('div',[_c('b-form-row',[_c('b-colxx',{attrs:{"md":"12","sm":"12"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchContacts.apply(null, arguments)}}},[_c('m-text-input',{attrs:{"id":"contact-search","label":"Search for contact","value":_vm.searchForContactName,"alternative":false,"readonly":false,"group":false,"name":"Search for Contact","append-icon":"fas fa-search text-success icon-pointer"},on:{"append-icon-action":function () {
                    _vm.searchContacts();
                  },"update-value":function (input) {
                    _vm.searchForContactName = input;
                  },"search-contacts":function($event){return _vm.searchContacts()}}})],1)],1)],1)],1):_vm._e(),_c('b-form-row',[_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[(!_vm.contactDropdownYn)?_c('m-text-input',{attrs:{"id":"contact-name","label":"Contact Name","value":_vm.salesCallData.contact_name,"alternative":true,"readonly":true,"rules":{ required: true },"group":false,"name":"Contact Name"},on:{"update-value":function (input) {
                _vm.salesCallData.contact_name = input;
                _vm.updateCallData();
              }}}):_vm._e(),_c('label',{staticClass:"field-label-form-group mt-2"},[_vm._v("Contact Name *")]),(_vm.contactDropdownYn)?_c('b-form-select',{attrs:{"id":"contact-select","options":_vm.contactOptions,"value-field":"contact_id","text-field":"full_name"},model:{value:(_vm.salesCallData.contact),callback:function ($$v) {_vm.$set(_vm.salesCallData, "contact", $$v)},expression:"salesCallData.contact"}}):_vm._e()],1),_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[_c('m-text-input',{attrs:{"id":"sales-rep-name","label":"Sales Rep","value":_vm.salesCallData.user_name,"alternative":true,"readonly":true,"rules":{ required: true },"group":false,"name":"Sales Rep"},on:{"update-value":function (input) {
                _vm.salesCallData.user_name = input;
                _vm.updateCallData();
              }}})],1),_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[_c('m-text-input',{attrs:{"id":"call-date","label":"Call Date","value":_vm.salesCallData.call_date,"alternative":false,"rules":{ required: true },"type":"datetime-local","step":"900","group":false,"disabled":!_vm.canEditSalesCall,"name":"Call Date"},on:{"update-value":function (input) {
                _vm.salesCallData.call_date = input;
                _vm.updateCallData();
              }}})],1),_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[_c('m-text-input',{attrs:{"id":"customer-name","label":"Customer","value":_vm.salesCallData.customer_name,"alternative":true,"readonly":true,"group":false,"name":"Customer"},on:{"update-value":function (input) {
                _vm.salesCallData.customer_name = input;
                _vm.updateCallData();
              }}})],1),_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',_vm._b({attrs:{"rules":"required","name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"field-label-form-group mt-2"},[_vm._v("Category *")]),_c('b-form-select',{staticClass:"custom-input-select form-control",attrs:{"options":_vm.salesCallCategories,"label-field":"'Category'","required":true,"disabled":!_vm.canEditSalesCall,"value-field":"sales_call_category_id","text-field":"sales_call_category"},on:{"change":function($event){return _vm.updateCallData()}},model:{value:(_vm.salesCallData.category),callback:function ($$v) {_vm.$set(_vm.salesCallData, "category", $$v)},expression:"salesCallData.category"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback text-xs",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,692893707)},'validation-provider',_vm.$attrs,false))],1),_c('b-colxx',{attrs:{"md":"12","sm":"12"}},[_c('m-text-area',{attrs:{"id":"call-data-note","value":_vm.salesCallData.notes,"name":"Notes","rows":7,"rules":{ required: false, max: 4000 },"label":"Notes","alternative":false,"group":false,"disabled":!_vm.canEditSalesCall},on:{"update-value":function (input) {
                _vm.salesCallData.notes = input;
                // updateCallData();
              },"blur":function($event){return _vm.updateCallData()}}})],1)],1),_c('b-form-row',{staticClass:"my-2"},[_c('b-colxx',{attrs:{"md":"12","sm":"12"}},[_c('b-table',{ref:"__activityTable",attrs:{"id":"activity-table","head-variant":"dark","responsive":"","items":_vm.salesCallData.activities,"fields":_vm.activity_list_fields,"per-page":0},scopedSlots:_vm._u([{key:"cell(due_date)",fn:function(row){return [_vm._v(" "+_vm._s(new Date(row.item.due_date).toLocaleDateString())+" ")]}},{key:"cell(related_outlook_task)",fn:function(row){return [(row.item.related_outlook_task_id !== '')?_c('div',[_c('i',{staticClass:"fas fa-circle-check text-success"})]):_vm._e()]}}],null,false,2584499294)})],1)],1),_c('b-form-row',[_c('b-colxx',{attrs:{"md":"12","sm":"12"}},[_c('b-alert',{attrs:{"variant":"warning"},model:{value:(_vm.noDefaultUserToDoList),callback:function ($$v) {_vm.noDefaultUserToDoList=$$v},expression:"noDefaultUserToDoList"}},[_vm._v("No default to do list selected. Go to your user profile and select a default list.")])],1)],1),_c('b-form-row',[_c('b-colxx',{attrs:{"md":"3","sm":"12"}},[_c('b-form-group',{staticClass:"field-label-form-group mt-2",attrs:{"id":"followup-activity-fieldset","label":"Create Follow Up Activity?","label-for":"create-activity-yn"}},[_c('b-form-checkbox',{attrs:{"id":"create-activity-yn","disabled":!_vm.canEditSalesCall},on:{"update":function($event){return _vm.updateCallData()},"change":function($event){return _vm.setTaskFields()}},model:{value:(_vm.salesCallData.activity_follow_up_yn),callback:function ($$v) {_vm.$set(_vm.salesCallData, "activity_follow_up_yn", $$v)},expression:"salesCallData.activity_follow_up_yn"}})],1)],1),(_vm.salesCallData.activity_follow_up_yn)?_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',_vm._b({attrs:{"rules":"required","name":"Assign To"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"field-label-form-group mt-2"},[_vm._v("Follow Up Assigned To *")]),_c('b-form-select',{staticClass:"custom-input-select form-control",attrs:{"options":_vm.userList,"label-field":"'Assign Activity To'","required":true,"disabled":!_vm.canEditSalesCall,"value-field":"user_id","text-field":"full_name"},on:{"change":function($event){return _vm.updateCallData()}},model:{value:(_vm.salesCallData.activity_assign_to),callback:function ($$v) {_vm.$set(_vm.salesCallData, "activity_assign_to", $$v)},expression:"salesCallData.activity_assign_to"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback text-xs",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2232645441)},'validation-provider',_vm.$attrs,false))],1):_vm._e(),(_vm.showOutlookTaskCheckbox)?_c('b-colxx',{attrs:{"md":"3","sm":"12"}},[_c('b-form-group',{staticClass:"field-label-form-group mt-2",attrs:{"id":"create-outlook-task-fieldset","label":"Create Outlook Task Reminder?","label-for":"create--outlook-task-yn"}},[_c('b-form-checkbox',{attrs:{"id":"create-outlook-task-yn","disabled":!_vm.canEditSalesCall},on:{"update":function($event){return _vm.updateCallData()}},model:{value:(_vm.salesCallData.activity_create_outlook_task_yn),callback:function ($$v) {_vm.$set(_vm.salesCallData, "activity_create_outlook_task_yn", $$v)},expression:"salesCallData.activity_create_outlook_task_yn"}})],1)],1):_vm._e()],1),(_vm.salesCallData.activity_follow_up_yn)?_c('b-form-row',[_c('b-colxx',{attrs:{"md":"3","sm":"12"}},[_c('m-text-input',{attrs:{"id":"reminder-date","label":"Reminder Date","value":_vm.salesCallData.activity_reminder_date,"alternative":false,"rules":{ required: _vm.salesCallData.activity_follow_up_yn },"type":"datetime-local","step":"900","group":false,"disabled":!_vm.canEditSalesCall,"name":"Reminder Date"},on:{"update-value":function (input) {
                _vm.salesCallData.activity_reminder_date = input;
                _vm.updateCallData();
              }}})],1),_c('b-colxx',{attrs:{"md":"3","sm":"12"}},[_c('m-text-input',{attrs:{"id":"due-date","label":"Due Date","value":_vm.salesCallData.activity_due_date,"alternative":false,"rules":{ required: _vm.salesCallData.activity_follow_up_yn },"type":"datetime-local","step":"900","group":false,"disabled":!_vm.canEditSalesCall,"name":"Due Date"},on:{"update-value":function (input) {
                _vm.salesCallData.activity_due_date = input;
                _vm.updateCallData();
              }}})],1),_c('b-colxx',{attrs:{"md":"6","sm":"12"}},[_c('m-text-input',{attrs:{"id":"task-subject","label":"Subject","value":_vm.salesCallData.activity_task_subject,"rules":{ required: _vm.salesCallData.follow_up_yn },"alternative":false,"group":false,"name":"Subject","disabled":!_vm.canEditSalesCall},on:{"update-value":function (input) {
                _vm.salesCallData.activity_task_subject = input;
                _vm.updateCallData();
              }}})],1),(_vm.salesCallData.activity_create_outlook_task_yn)?_c('b-colxx',{attrs:{"md":"12","sm":"12"}},[_c('validation-provider',_vm._b({attrs:{"rules":"required","name":"Choose a task list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{staticClass:"field-label-form-group mt-2"},[_vm._v("Outlook To Do List *")]),_c('b-form-select',{staticClass:"custom-input-select form-control",attrs:{"options":_vm.outlookToDoListOptions,"label-field":"'Outlook To Do List'","required":true,"value-field":"id","text-field":"displayName","disabled":!_vm.canEditSalesCall},on:{"change":function($event){return _vm.updateCallData()}},model:{value:(_vm.salesCallData.outlook_to_do_list),callback:function ($$v) {_vm.$set(_vm.salesCallData, "outlook_to_do_list", $$v)},expression:"salesCallData.outlook_to_do_list"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback text-xs",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1366538)},'validation-provider',_vm.$attrs,false))],1):_vm._e(),_c('b-colxx',{attrs:{"md":"12","sm":"12"}},[_c('m-text-area',{attrs:{"id":"follow-up-note","value":_vm.salesCallData.activity_follow_up_note,"name":"Task Notes","rows":7,"rules":{ required: false, max: 4000 },"label":"Task Notes","alternative":false,"group":false,"disabled":!_vm.canEditSalesCall},on:{"update-value":function (input) {
                _vm.salesCallData.activity_follow_up_note = input;
                //updateCallData();
              },"blur":function($event){return _vm.updateCallData()}}})],1)],1):_vm._e(),_c('m-tracking-info',{attrs:{"created-by-name":_vm.salesCallData.created_by_name,"created-at":_vm.salesCallData.created_at,"modified-by-name":_vm.salesCallData.modified_by_name,"modified-at":_vm.salesCallData.modified_at}})],1):_vm._e(),(_vm.showSearchSideBar)?_c('div',[(!_vm.createContact)?_c('div',[_c('b-table',{ref:"__searchContactTable",attrs:{"id":"search-contact-table","responsive":"","head-variant":"dark","items":_vm.contactSearchResults,"fields":_vm.contact_search_fields},scopedSlots:_vm._u([{key:"cell(select)",fn:function(row){return [_c('i',{staticClass:"fas fa-circle-check text-primary icon-pointer",on:{"click":function($event){return _vm.selectContact(row.item)}}})]}},{key:"custom-foot",fn:function(){return [_c('td',{attrs:{"colspan":"3"}},[_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){return _vm.closeContactSearch()}}},[_vm._v("Close")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.createNewContact()}}},[_vm._v("Create New Contact")])],1)]},proxy:true}],null,false,1302426027)})],1):_vm._e()]):_vm._e(),(_vm.createContact)?_c('div',[_c('validation-observer',{ref:"__validateContactForm",scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('contact',{attrs:{"item":_vm.newContactData,"edit-contact-y-n":false,"show-updated-fields":false},on:{"hide-save-contact-button":function($event){return _vm.toggleSaveButtons($event)},"update-contact-data":function($event){return _vm.updateContctData($event)}}}),(!_vm.hideButtons)?_c('b-row',{staticClass:"my-1 mx-auto"},[_c('b-button',{attrs:{"variant":"success","disabled":!observer.valid},on:{"click":function($event){return _vm.saveNewContact()}}},[_vm._v("Save")]),_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.cancelNewContact()}}},[_vm._v("Cancel")])],1):_vm._e()]}}],null,false,3052660144)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }