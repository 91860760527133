<template>
  <div class="">
    <div v-if="!showLoader">
      <m-page-title title="Contacts" />
      <b-row class="d-flex my-3">
        <b-colxx cols="12">
          <b-card>
            <b-row>
              <b-colxx
                md="6"
                sm="12"
                ><b-form
                  id="customer-search-main"
                  @submit.prevent="getContactList">
                  <b-form-group class="mb-0">
                    <b-input-group class="">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                      </div>
                      <b-form-input
                        v-model="searchContactName"
                        placeholder="Search by contact name"
                        type="text">
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-form>
              </b-colxx>
              <b-colxx
                md="2"
                sm="12">
                <b-form-group
                  id="contact-active-fieldset"
                  label="Hide Inactive"
                  label-for="hide-contact-inactive-yn"
                  class="field-label-form-group">
                  <b-form-checkbox
                    id="hide-contact-inactive-yn"
                    v-model="hideInactiveYN"></b-form-checkbox>
                </b-form-group>
              </b-colxx>
              <b-colxx
                md="4"
                sm="12">
                <b-button
                  variant="success"
                  @click="getContactList()"
                  >Search</b-button
                >
                <b-button
                  variant="info"
                  @click="clearSearchFilters()"
                  >Clear</b-button
                >
                <b-button
                  v-if="permissions.includes('CanCreateContact')"
                  variant="primary"
                  @click="createNewContact()"
                  ><i class="fas fa-circle-plus text-white mr-1"></i>New Contact</b-button
                >
              </b-colxx>
            </b-row>
          </b-card>
        </b-colxx>
      </b-row>
      <div class="my-4">
        <b-table
          id="contact-table"
          ref="__contactTable"
          responsive
          head-variant="dark"
          :items="filteredContactList"
          :fields="contact_list_fields"
          :current-page="current_page"
          :busy="showLoader"
          per-page="0">
          <template #cell(view)="row">
            <i
              v-if="permissions.includes('CanViewContact')"
              class="fas fa-eye text-default icon-pointer mr-2"
              @click="goContactDetail(row.item, row.index)" />
            <i
              v-if="permissions.includes('CanCreateSalesCall')"
              class="fas fa-phone-plus icon-pointer text-primary"
              @click="openCreateSalesCall(row.item, row.index)" />
            <i
              v-if="row.item.is_active_yn == false"
              class="fas fa-do-not-enter text-warning ml-2"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="current_page"
          :total-rows="total_rows"
          :per-page="per_page"
          :number-of-pages="pageTotal"
          size="sm"
          class="my-0"
          aria-controls="contact-table"
          @input="getContactList(current_page)"></b-pagination>
      </div>
    </div>

    <!--  -->
    <!-- View Contact Modal -->
    <!--  -->
    <div id="contact-detail-modal-div">
      <validation-observer
        ref="__validateContactForm"
        v-slot="observer">
        <b-modal
          id="contact-modal"
          v-model="showContactModal"
          :title="contactModalTitle"
          centered
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          scrollable
          :hide-footer="waitGettingContact || hideContactModalTabsButtons"
          header-bg-variant="info"
          header-text-variant="white"
          size="xl">
          <div v-if="waitGettingContact">
            <m-spinny-message message="Please wait while we grab the data..." />
          </div>
          <div v-if="!waitGettingContact">
            <b-tabs pills>
              <b-tab
                title="Details"
                active>
                <div class="my-1">
                  <contact
                    ref="__contactDetail"
                    :item="editContact"
                    :edit-contact-y-n="editContactYN"
                    :customer-locations="customerLocations"
                    :company-detail="companyData"
                    @update-contact-data="updateContactData($event)"
                    @delete-contact="deleteContact($event)"
                    @hide-tabs-buttons="hideTabsAndButtonsInModal($event)" />
                </div>
              </b-tab>
              <!-- <div v-if="permissions.includes('canViewSalesCall')"> -->
              <b-tab
                v-if="editContactYN || permissions.includes('CanViewSalesCall')"
                :disabled="hideContactModalTabsButtons"
                title="Sales Calls">
                <div class="my-1">
                  <b-table
                    id="sales-call-table"
                    ref="__salesCallTable"
                    responsive
                    head-variant="dark"
                    :items="editContact.sales_calls"
                    :fields="sales_call_list_fields"
                    :current-page="current_page"
                    :per-page="0">
                    <template #cell(call_date)="row">
                      {{ new Date(row.item.call_date).toLocaleDateString() }}
                    </template>
                  </b-table>
                </div></b-tab
              >
              <!-- </div> -->
              
            </b-tabs>
          </div>
          <template #modal-footer>
            <b-button
              v-if="!contactSaveComplete"
              class="mr-2"
              variant="info"
              :disabled="contactIsSaving || !observer.valid"
              @click="saveContact()">
              {{ contactSaveButtonLabel }}
            </b-button>
            <b-button
              variant="warning"
              :disabled="contactIsSaving"
              @click="closeContact()"
              >Close</b-button
            >
          </template>
        </b-modal>
      </validation-observer>
    </div>

    <!--  -->
    <!-- Create Sales Call Modal -->
    <!--  -->
    <div id="sales-call-modal-div">
      <validation-observer
        ref="__validateSalesCallForm"
        v-slot="observer">
        <b-modal
          id="sales-call-modal"
          v-model="showSalesCallModal"
          title="New Sales Call"
          centered
          scrollable
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          header-bg-variant="info"
          header-text-variant="white"
          size="lg">
          <sales-call
            ref="__salesCallDetail"
            :item="editSalesCall"
            :user-name="user.full_name"
            :user-id="user.user_id"
            :is-edit-sales-call="true"
            :show-contact-search="false"
            @update-call-data="updateSalesCall($event)" />
          <template #modal-footer="{}">
            <div v-if="canEditSalesCall">
              <b-button
                v-if="!saveSalesCallComplete"
                class="mr-2"
                variant="info"
                :disabled="salesCallIsSaving || !observer.valid"
                @click="salesCallSave()">
                {{ salesCallSaveButtonLabel }}
              </b-button>
            </div>
            <b-button
              variant="warning"
              :disabled="salesCallIsSaving"
              @click="closeSalesCall()"
              >Close</b-button
            >
          </template>
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"

  import Contact from "@/views/customer/components/Contact.vue"
  import SalesCall from "@/views/customer/components/SalesCall.vue"
  import { contactObj } from "@/constants/objects"
  import { salesCallObj } from "@/constants/objects/salescall"

  import { saveSalesCall } from "@/functions/salescall"

  import { newLocalTodayDateObject } from "@/functions/time"
  // import MSpinnyMessage from "@/components/Loader/MSpinnyMessage.vue";

  // import { province } from "@/constants/province";
  const companyData = {
    busemailad: false
  }

  export default {
    name: "ContactHome",
    components: {
      Contact,
      SalesCall,
      // MSpinnyMessage,
    },

    data() {
      return {
        companyData: companyData,
        newLocalTodayDateObject,
        contactList: [],
        contact_list_fields: [
          {
            label: "",
            key: "view",
          },
          {
            label: "Name",
            key: "full_name",
          },
          {
            label: "Company",
            key: "customer_name",
          },
          {
            label: "Email",
            key: "email",
          },
          {
            label: "Phone",
            key: "phone",
          },
          {
            label: "Position",
            key: "position",
          },
          {
            label: "Sales Calls",
            key: "sales_call_count",
          },
        ],

        sales_call_list_fields: [
          {
            label: "Notes",
            key: "notes",
          },
          {
            label: "Created By",
            key: "created_by_name",
          },
          {
            label: "Category",
            key: "category_name",
          },
          {
            label: "Call Date",
            key: "call_date",
          },
        ],

        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",

        searchContactName: "",
        hideInactiveYN: true,

        contactObj,
        editContact: {},
        editContactIndex: null,
        editContactYN: false,
        waitGettingContact: false,
        hideContactModalTabsButtons: false,

        showContactModal: false,
        showContactAlert: false,
        contactAlertMessage: "",
        contactAlertVariant: "",
        contactIsSaving: false,
        contactSaveComplete: false,

        customerLocations: [],

        salesCallObj,
        showSalesCallModal: false,
        editSalesCall: {},
        editSalesCallYN: false,
        salesCallIsSaving: false,
        saveSalesCallComplete: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["currentPage", "showLoader"]),

      pageTotal() {
        var page_total = 1
        page_total = Math.ceil(this.total_rows / this.per_page)
        return page_total
      },

      contactModalTitle() {
        if (this.editContactYN === false) {
          return "Create New Contact"
        } else {
          return "Edit Contact"
        }
      },

      contactSaveButtonLabel() {
        if (this.contactIsSaving === true) {
          return "Saving..."
        } else {
          return "Save"
        }
      },

      salesCallSaveButtonLabel() {
        if (this.salesCallIsSaving === true) {
          return "Saving..."
        } else {
          return "Save"
        }
      },

      canEditSalesCall() {
        if (this.permissions.includes("CanEditSalesCall")) {
          return true
        } else {
          return false
        }
      },

      filteredContactList() {
        let list = this.contactList
        if (this.hideInactiveYN === true) {
          let filteredList = list.filter((x) => x.is_active_yn === true)
          return filteredList
        }
        return list
      },
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true)
      document.title = "Contact List"
      await this.getContactList()
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async getContactList() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("erp/customer/contact/search/", {
            params: {
              page: this.current_page,
              keyword: this.searchContactName,
            },
          })
          .then((response) => {
            console.log(response.data)
            this.total_rows = response.data.count
            this.prev_page_link = response.data.links.previous
            this.next_page_link = response.data.links.next
            this.current_page = response.data.page
            this.contactList = response.data.results
          })
          .catch((error) => {
            console.log(error)
          })
        this.$store.dispatch("common/showLoader", false)
      },

      clearSearchFilters() {
        this.current_page = 1
        this.searchContactName = ""
        this.getContactList()
      },

      async goContactDetail(item, index) {
        this.waitGettingContact = true
        await django_session.get("erp/customer/contact/" + item.contact_id + "/").then((response) => {
          console.log("Get contact data", response.data)
          this.editContact = JSON.parse(JSON.stringify(response.data))
          this.editContactIndex = index
          this.editContactYN = true
        })
        this.waitGettingContact = false
        this.showContactModal = true
      },

      updateContactData(event) {
        this.editContact = event
      },

      hideTabsAndButtonsInModal(value) {
        console.log(value)
        this.hideContactModalTabsButtons = value
      },

      createNewContact() {
        this.editContactIndex = null
        this.editContactYN = false
        this.editContact = JSON.parse(JSON.stringify(contactObj))
        this.showContactModal = true
      },

      async deleteContact(e) {
        this.contactIsSaving = true
        let contactIndex = this.contactList.findIndex((x) => x.contact_id === e.contact_id)
        await django_session.delete("erp/customer/contact/" + e.contact_id + "/").then((response) => {
          console.log(response)
          this.contactIsSaving = false
          this.contactList.splice(contactIndex, 1)
          this.$refs.__contactDetail.contactAlertVariant = "success"
          this.$refs.__contactDetail.contactAlertMessage = "Contact deleted."
          this.$refs.__contactDetail.showContactAlert = true
        }).catch((error) => {
          console.log(error)
          this.contactIsSaving = false
          this.$refs.__contactDetail.contactAlertVariant = "danger"
          this.$refs.__contactDetail.contactAlertMessage = "Error deleting contact. " + error.response.data
          this.$refs.__contactDetail.showContactAlert = true
        })
      },

      async saveContact() {
        this.contactIsSaving = true
        if (this.editContactYN === true) {
          await django_session
            .patch("erp/customer/contact/" + this.editContact.contact_id + "/", {
              email: this.editContact.email,
              first_name: this.editContact.first_name,
              last_name: this.editContact.last_name,
              notes: this.editContact.notes,
              phone: this.editContact.phone,
              position: this.editContact.position,
              is_active_yn: this.editContact.is_active_yn,
              // sage_customer_id: this.editContact.sage_customer_id,
              // sage_customer_location_id: this.editContact.sage_customer_location_id,
              erp_customer_id: this.editContact.erp_customer_id,
              address_1: this.editContact.address_1,
              address_2: this.editContact.address_2,
              city: this.editContact.city,
              province: this.editContact.province,
              postal_code: this.editContact.postal_code,
              updated_by: this.user.user_id
            })
            .then((response) => {
              console.log(response)
              this.editContact.updated_at = response.data.updated_at
              this.editContact.updated_by = response.data.updated_by
              this.editContact.updated_by_name = response.data.updated_by_name
              this.contactList[this.editContactIndex].email = response.data.email
              this.contactList[this.editContactIndex].first_name = response.data.first_name
              this.contactList[this.editContactIndex].last_name = response.data.last_name
              this.contactList[this.editContactIndex].notes = response.data.notes
              this.contactList[this.editContactIndex].phone = response.data.phone
              this.contactList[this.editContactIndex].position = response.data.position
              this.contactList[this.editContactIndex].is_active_yn = response.data.is_active_yn
              // this.contactList[this.editContactIndex].sage_customer_id = response.data.sage_customer_id
              // this.contactList[this.editContactIndex].sage_customer_location_id =
              //   response.data.sage_customer_location_id
              this.contactList[this.editContactIndex].customer_name = response.data.customer_name
              this.contactList[this.editContactIndex].erp_customer_id = response.data.erp_customer_id
              this.contactList[this.editContactIndex].updated_at = response.data.updated_at
              this.contactList[this.editContactIndex].updated_by = response.data.updated_by
              this.contactList[this.editContactIndex].updated_by_name = response.data.updated_by_name
              this.saveContactComplete = true
              this.$refs.__contactDetail.contactAlertVariant = "success"
              this.$refs.__contactDetail.contactAlertMessage = "Contact information updated."
              this.$refs.__contactDetail.showContactAlert = true
            })
            .catch((error) => {
              console.log(error.response)
              this.$refs.__contactDetail.contactAlertVariant = "danger"
              this.$refs.__contactDetail.contactAlertMessage = "Error updating contact."
              this.$refs.__contactDetail.showContactAlert = true
              this.saveContactComplete = true
            })
        }

        if (this.editContactYN === false) {
          await django_session
            .post("erp/customer/contact/create/", {
              email: this.editContact.email,
              first_name: this.editContact.first_name,
              last_name: this.editContact.last_name,
              notes: this.editContact.notes,
              phone: this.editContact.phone,
              position: this.editContact.position,
              is_active_yn: this.editContact.is_active_yn,
              // sage_customer_id: this.editContact.sage_customer_id,
              // sage_customer_location_id: this.editContact.sage_customer_location_id,
              erp_customer_id: this.editContact.erp_customer_id,
              address_1: this.editContact.address_1,
              address_2: this.editContact.address_2,
              city: this.editContact.city,
              province: this.editContact.province,
              postal_code: this.editContact.postal_code,
              created_by: this.user.user_id,
              updated_by: this.user.user_id
            })
            .then((response) => {
              console.log(response)
              this.editContact.contact_id = response.data.contact_id
              this.editContact.updated_at = response.data.updated_at
              this.editContact.updated_by = response.data.updated_by
              this.editContact.updated_by_name = response.data.updated_by_name
              this.editContact.created_by = response.data.created_by
              this.editContact.created_by_name = response.data.created_by_name
              this.editContact.created_at = response.data.created_at
              this.editContact.full_name = response.data.full_name
              this.editContact.customer_name = response.data.customer_name
              this.editContact.erp_customer_id = response.data.erp_customer_id
              this.editContact.sales_call_count = 0
              this.contactList.unshift(this.editContact)
              this.$refs.__contactDetail.contactAlertVariant = "green"
              this.$refs.__contactDetail.contactAlertMessage = "New contact created."
              this.$refs.__contactDetail.showContactAlert = true
            })
            .catch((error) => {
              console.log(error)
              this.$refs.__contactDetail.contactAlertVariant = "red"
              this.$refs.__contactDetail.contactAlertMessage = "Error creating contact."
              this.$refs.__contactDetail.showContactAlert = true
            })
        }
        this.contactIsSaving = false
        this.contactSaveComplete = true
      },

      closeContact() {
        this.editContactIndex = null
        this.editContactYN = false
        this.showContactModal = false
        this.contactSaveComplete = false
      },

      openCreateSalesCall(item, index) {
        this.editContact = item
        this.editContactIndex = index
        this.editSalesCall = JSON.parse(JSON.stringify(salesCallObj))

        // let today = new Date().toUTCString();
        // let splitDateTime = dateTimeNoOffsetNoSeconds(today);
        this.editSalesCall.call_date = newLocalTodayDateObject()

        this.editSalesCall.contact_name = item.full_name
        this.editSalesCall.contact = item.contact_id
        this.editSalesCall.customer_name = item.customer_name
        this.editSalesCall.customer = item.erp_customer_id
        this.editSalesCall.contact_phone = item.phone
        // this.editSalesCall.call_date = splitDateTime.date_string;
        // this.editSalesCall.call_time = splitDateTime.local_time_string;
        this.editSalesCall.is_new_contact_yn = false
        this.editSalesCall.user = this.user.user_id
        this.editSalesCall.user_name = this.user.full_name
        this.editSalesCall.activity_assign_to = this.user.user_id
        this.editSalesCall.notes = ""
        this.showSalesCallModal = true
      },

      updateSalesCall(event) {
        this.editSalesCall = event
      },

      async salesCallSave() {
        this.salesCallIsSaving = true
        let saveSalesCallRecord = await saveSalesCall(this.editSalesCall, false)
        console.log("Wait for Function", saveSalesCallRecord)

        if (saveSalesCallRecord.status === 200) {
          this.$refs.__salesCallDetail.salesCallAlertMessage = "Sales call information updated."
          this.$refs.__salesCallDetail.salesCallAlertVariant = "success"
          this.$refs.__salesCallDetail.showSalesCallAlert = true
          this.salesCallIsSaving = false
          this.saveSalesCallComplete = true

          this.editSalesCall.created_by_name = saveSalesCallRecord.data.created_by_name
          this.editSalesCall.created_at = saveSalesCallRecord.data.created_at
          this.editSalesCall.modified_by_name = saveSalesCallRecord.data.modified_by_name
          this.editSalesCall.modified_at = saveSalesCallRecord.data.modified_at
          this.contactList[this.editContactIndex].sales_call_count =
            this.contactList[this.editContactIndex].sales_call_count + 1
        }
        if (saveSalesCallRecord.status === 500) {
          this.$refs.__salesCallDetail.salesCallAlertMessage = "Server Error. Please contact the developer."
          this.$refs.__salesCallDetail.salesCallAlertVariant = "danger"
          this.$refs.__salesCallDetail.showSalesCallAlert = true
          this.salesCallIsSaving = false
          this.saveSalesCallComplete = true
        }
      },

      closeSalesCall() {
        this.showSalesCallModal = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .customer-name {
    font-weight: 700;
  }

  span {
    display: block;
  }
</style>
